import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import { formatDate } from 'helpers/datetime';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import App from 'modules/App';
import Account from 'modules/Account';
import FormContainerAbstract from 'components/FormContainerAbstract';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as shapes from '../../shapes';
import messages from '../../messages';
import styles from '../ModalCommon.pcss';
import validatorRules from './validatorRules.json';


class MergeOptions extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    activePatient: PropTypes.shape({
      firstName  : PropTypes.string.isRequired,
      lastName   : PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string,
    }),
    clinicPatient: PropTypes.shape({
      firstName  : PropTypes.string.isRequired,
      lastName   : PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string,
    }),
    sharingRequest        : shapes.sharingRequest,
    // Explicit actions
    onSetComponent        : PropTypes.func,
    onClose               : PropTypes.func,
    // Implicit props
    activeClinicMembership: Account.shapes.clinicMembership,
    // Implicit actions
    onAccept              : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      option: null,
    };
    this.validatorRules = validatorRules;
    this.onSetOption({ value: props.clinicPatient ? 'bind' : 'searchAndBind' });
  }


  onSetOption(input) {
    input.id = 'option';
    this.onSetValue(input);
  }


  onSetValues(formName, values) {
    this.props.onSetFormValues(formName, values);
  }


  onSubmit() {
    this.props.onFormProcessing();
    const { validatedValues, errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
    if (!errors) {
      const { option } = validatedValues;
      if (option === 'searchAndBind') {
        this.props.onSetComponent('ChoosePatient');
        return;
      }
      const { activePatient, clinicPatient, sharingRequest, activeClinicMembership } = this.props;
      this.props.onSubmit(activePatient, clinicPatient, sharingRequest, activeClinicMembership);
      this.props.onSetComponent('Merging');
    }
  }


  get isDisabled() {
    const { errors } = this.onValidate(this.requiredRules);
    return !!errors;
  }


  get isOpen() {
    return this.props.openModalId === constants.MERGE_PATIENT_MODAL;
  }


  renderActions() {
    return (
      <div className="modal__actions row">
        <div className="col-6">
          <Button
            styleModifier="quaternary"
            labelMessage={messages.buttons.cancel}
            className="btn--block"
            onClick={this.props.onClose}
          />
        </div>
        <div className="col-6">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.submit}
            className="btn--block btn--filled"
            isDisabled={this.isDisabled}
            onClick={() => this.onSubmit()}
          />
        </div>
      </div>
    );
  }


  renderPerson(person) {
    const { avatar, firstName, lastName, dateOfBirth } = person || {};
    return (
      <div className={cn('row align-items-center', styles.person)}>
        <div className="col-auto pr-0">
          <Avatar
            avatarImg={avatar}
            name={[firstName, lastName]}
            className={styles.item__avatar}
            imgClassName={styles.item__avatar__img}
            initialsClassName={styles.item__avatar__initials}
          />
        </div>
        <div className="col">
          <p className={styles.person__name}>{ firstName } { lastName }</p>
          <p className="text--small">
            <span className="text--muted"><FormattedMessage {...messages.labels.dateOfBirth} />{ ': ' }</span>
            { dateOfBirth ? formatDate(dateOfBirth) : '-' }
          </p>
        </div>
      </div>
    );
  }


  renderActivePatient() {
    const { activePatient } = this.props;
    if (!activePatient) {
      return null;
    }
    return (
      <div className="px-3">
        <h3 className="text--bold"><FormattedMessage {...messages.labels.activeProfile} /></h3>
        { this.renderPerson(activePatient) }
      </div>
    );
  }


  renderClinicPatient() {
    const { clinicPatient } = this.props;
    if (!clinicPatient) {
      return null;
    }
    return (
      <div className="px-3">
        <h3 className="text--bold"><FormattedMessage {...messages.labels.existingProfile} /></h3>
        { this.renderPerson(clinicPatient) }
      </div>
    );
  }


  renderComparison() {
    return (
      <div className="row">
        <div className="col-6">{ this.renderActivePatient() }</div>
        <div className="col-6">{ this.renderClinicPatient() }</div>
      </div>
    );
  }


  renderOptions() {
    const value = get(this.props.formValues, 'values.option');
    return (
      <div>
        <h3 className="text--bold my-4">
          <FormattedMessage {...messages.headers.whatToDoWithRequest} />
        </h3>
        <div className="form-group">
          {
            this.props.clinicPatient
            && (
              <CheckboxRadio
                id="option-bind"
                inputValue="bind"
                value={value}
                labelMessage={messages.labels.mergeWithFoundProfile}
                isRadio
                onChange={(input) => this.onSetOption(input)}
              />
            )
          }
        </div>
        <div className="form-group">
          <CheckboxRadio
            id="option-searchAndBind"
            inputValue="searchAndBind"
            value={value}
            labelMessage={messages.labels.mergeWithOtherProfile}
            isRadio
            onChange={(input) => this.onSetOption(input)}
          />
        </div>
      </div>
    );
  }


  render() {
    return (
      <div>
        <div className={styles.container} data-testid="patient-data-comparison">
          { this.renderComparison() }
          { this.renderOptions() }
        </div>
        { this.renderActions() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
  formValues            : App.selectors.formSelector(constants.MERGE_PATIENT_FORM)(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.MERGE_PATIENT_FORM;
  return {
    onSubmit: (activePatient, clinicPatient, sharingRequest, clinicMembership) => dispatch(
      actions.mergePatient(activePatient, clinicPatient, sharingRequest, clinicMembership),
    ),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues : (form, values) => dispatch(App.actions.setFormValues(form, values)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
  };
};


const ConnectedEnrollingRequest = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MergeOptions);


export default ConnectedEnrollingRequest;
