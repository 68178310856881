import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import cn from 'classnames';
import { formatDate } from 'helpers/datetime';
import App from 'modules/App';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import styles from './ChoosePatient.pcss';


interface EntryValueProps {
  value?: string,
}

interface EntryProps extends EntryValueProps {
  labelMessage: MessageDescriptor,
}

interface Props {
  firstName?: string,
  lastName?: string,
  dateOfBirth?: string,
  gender?: string,
}


const placeholder = 'N/A';


const EntryValue: FC<EntryValueProps> = ({ value }) => (
  <p className={cn(styles.patientData__value, { [styles['patientData__value--placeholder']]: !value })}>
    { value || placeholder }
  </p>
);


const Entry: FC<EntryProps> = ({ value, labelMessage }) => (
  <div className={styles.patientData__entry}>
    <p className={styles.patientData__label}><FormattedMessage {...labelMessage} /></p>
    <EntryValue value={value} />
  </div>
);


const PatientData: FC<Props> = ({ firstName, lastName, dateOfBirth, gender }) => {
  const clinicPatientTemplate = useSelector(selectors.clinicPatientTemplate);
  const localizationResources = useSelector(App.selectors.localizationResources);

  const localizedDateOfBirth = formatDate(dateOfBirth);

  const genderFieldDef = clinicPatientTemplate.find((ft) => ft.name === 'gender');
  const option = genderFieldDef.options.find((opt) => opt.value === gender);
  const localizedGender = option && localizationResources[option.labelKey].value;


  return (
    <div className={styles.patientData}>
      <Entry value={firstName} labelMessage={messages.labels.firstName} />
      <Entry value={lastName} labelMessage={messages.labels.lastName} />
      <Entry value={localizedGender} labelMessage={messages.labels.gender} />
      <Entry value={localizedDateOfBirth} labelMessage={messages.labels.dateOfBirth} />
    </div>
  );
};

export default PatientData;
