import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useAction, useServerEffect } from 'hooks';
import * as actions from '../../actions';
import { DATA_SOURCES_TYPE_NAME } from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import ConnectDataSourceModal from '../../components/ConnectDataSourceModal';
import ExternalDataSourcesIntro from '../ExternalDataSourcesIntro';
import ExternalDataSource from '../ExternalDataSource';


interface Props {
  consumer?: ScopeName,
}


const ExternalDataSources: FC<Props> = ({ consumer }) => {
  const dataSources = useSelector(selectors.dataSources(consumer));
  const connectedDataSources = useSelector(selectors.connectedDataSources);
  const fetchConnectedDataSources = useAction(actions.fetchConnectedDataSources);

  const effect = () => {
    if (!connectedDataSources) fetchConnectedDataSources();
  };

  useServerEffect(effect);
  useEffect(effect, []);

  const dataSourcesWithoutCC = dataSources.filter(
    (ds) => ds.dataSourceProvider !== DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD,
  );

  if (!dataSourcesWithoutCC.length) {
    return null;
  }

  return (
    <div>
      <ExternalDataSourcesIntro />
      <h3 className="form-label text--uppercase mb-3">
        <FormattedMessage {...messages.headers.availablePlatforms} />
      </h3>
      {
        dataSourcesWithoutCC.map((ds) => (
          <ExternalDataSource key={ds.externalDataSourceId} externalDataSource={ds} />
        ))
      }
      <ConnectDataSourceModal />
    </div>
  );
};

export default ExternalDataSources;
