import React, { FC, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import SidebarPersonsLoader from 'components/Loaders/SidebarPersonsLoader';
import * as selectors from '../../../selectors';
import styles from '../PatientsList.pcss';
import ActivePatient from './ActivePatient';


interface Props {
  id: string,
  patients: PatientRecord[],
  styleModifier?: 'halfSpace',
  onPatientClick: (evt: MouseEvent, patient: PatientRecord) => void,
}


const ActivePatientsList: FC<Props> = ({ id, patients, styleModifier, onPatientClick }) => {
  const isInProgress = useSelector(selectors.isFetchPatientsInProgress);
  useStyles(styles);

  if (isInProgress) {
    return <SidebarPersonsLoader itemsNumber={5} />;
  }

  const mapFilteredCollection = (patient: PatientRecord) => (
    <ActivePatient key={patient.id} {...{ id, patient, styleModifier, onPatientClick }} />
  );

  return (
    <ul id={id} className={styles.items}>
      { patients.map(mapFilteredCollection) }
    </ul>
  );
};

export default ActivePatientsList;
