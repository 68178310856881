import React, { FC, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { PATIENTS_LIST_LENGTH } from '../../constants';
import { useSearchPatients } from '../../hooks';
import * as selectors from '../../selectors';
import ActivePatientsList from '../PatientsList/ActivePatientsList';


interface Props {
  id: string,
  className: string,
  onPatientClick?: (evt: MouseEvent, patient: PatientRecord) => void,
}


const SearchPatientsList: FC<Props> = ({ id, className, onPatientClick }) => {
  const search = useSelector(selectors.searchSelector(id));
  const omitPatient: PatientRecord = useSelector(selectors.activePatient);
  const patients = useSearchPatients(search, { omitPatient, maxLength: PATIENTS_LIST_LENGTH, emptyDefault: true });

  return (
    <AnimatePresence>
      <motion.div
        initial={{ height: 0 }}
        animate={patients.length === 0 ? { display: 'none' } : { height: 'auto', display: 'block' }}
        exit={{ height: 0 }}
        className={className}
      >
        <ActivePatientsList {...{ id, patients, styleModifier: 'halfSpace', onPatientClick }} />
      </motion.div>
    </AnimatePresence>
  );
};

export default SearchPatientsList;
