import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import StaticText from 'components/Form/StaticText';
import App from 'modules/App';
import Account from 'modules/Account';
import { useFindSimilarPatient } from '../../../hooks';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import SearchPatients from '../../../partials/SearchPatients';
import SearchPatientsList from '../../../partials/SearchPatientsList';
import PatientData from './PatientData';
import styles from './ChoosePatient.pcss';


interface Props {
  setComponentName: (componentName: string) => void,
}


const ChoosePatient: FC<Props> = ({ setComponentName }) => {
  const activePatient: PatientRecord = useSelector(selectors.activePatient);
  const similarPatient: PatientRecord = useFindSimilarPatient(activePatient);
  const sharingRequest = useSelector(selectors.sharingRequest);
  const activeClinicMembership: ClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const mergePatient = useAction(actions.mergePatient, activePatient);
  const closeModal = useAction(App.actions.closeModal);
  useStyles(styles);

  const [destinationPatient, setDestinationPatient] = useState<PatientRecord>(similarPatient);

  useEffect(() => {
    setDestinationPatient(similarPatient);
  }, [similarPatient]);

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = () => {
    mergePatient(destinationPatient, sharingRequest, activeClinicMembership);
    setComponentName('Merging');
  };

  const onPatientClick = (evt: MouseEvent, patient: PatientRecord) => {
    evt.preventDefault();
    setDestinationPatient(patient);
  };

  return (
    <div>
      <p className="modal__info mb-7"><FormattedMessage {...messages.infos.mergePatients} /></p>
      <div className="row">
        <div className="col-6">
          <p className="caption"><FormattedMessage {...messages.labels.activeProfile} /></p>
          <div className="form-group">
            <StaticText value={`${activePatient.firstName} ${activePatient.lastName}`} />
          </div>
          <PatientData {...activePatient} />
        </div>
        <div className="col-6">
          <p className="caption"><FormattedMessage {...messages.labels.existingProfile} /></p>
          <div className={styles.searchContainer}>
            <SearchPatients forId="mergePatients" />
            <SearchPatientsList id="mergePatients" className={styles.foundPatients} onPatientClick={onPatientClick} />
          </div>
          <PatientData {...destinationPatient} />
        </div>
      </div>
      <div className="modal__actions">
        <div className="row">
          <div className="col-6">
            <Button
              styleModifier="primary"
              labelMessage={messages.buttons.cancel}
              className="btn--block mb-4"
              onClick={onCancel}
            />
          </div>
          <div className="col-6">
            <Button
              styleModifier="primary"
              labelMessage={messages.buttons.mergePatient}
              className="btn--block btn--filled"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoosePatient;
