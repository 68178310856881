import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useAction } from 'hooks';
import Modal from 'components/Modal';
import Form from 'components/Form';
import App from 'modules/App';
import * as actions from '../../actions';
import { CONNECT_DATA_SOURCE_MODAL, CONNECT_DATA_SOURCE_FORM } from '../../constants';
import messages from '../../messages';
import Actions from './Actions';
import Field from './Field';
import validatorRules from './validatorRules.json';
import styles from './ConnectDataSourceModal.pcss';


const ConnectDataSourceModal: FC = () => {
  const openModalId = useSelector(App.selectors.modal);
  const formValues = useSelector(App.selectors.formSelector(CONNECT_DATA_SOURCE_FORM));
  const localizationResources = useSelector(App.selectors.localizationResources);

  const dataSource = formValues?.contextData?.externalDataSource || {};

  const closeModal = useAction(App.actions.closeModal);
  const clearForm = useAction(App.actions.clearForm, CONNECT_DATA_SOURCE_FORM);
  const connect = useAction(actions.connect, dataSource);

  useStyles(styles);

  const onClose = () => {
    closeModal();
    clearForm();
  };

  const submitForm = (dataSourceCredentials: DataSourceCredentials) => {
    connect(dataSourceCredentials);
  };
  const processForm = App.hooks.useFormProcessing(CONNECT_DATA_SOURCE_FORM, submitForm);
  const onSubmit = () => processForm(formValues, validatorRules);

  const { dataSourceProvider = '', nameKey = '', logo = '' } = dataSource;
  const dataSourceName = localizationResources?.[nameKey]?.value || dataSourceProvider;
  const headerMessage = {
    ...messages.headers.connectToDataSource,
    values: { dataSourceName },
  };
  const introMessage = messages.intros.dataSource[dataSourceProvider];

  return (
    <Modal {...{ modalId: CONNECT_DATA_SOURCE_MODAL, openModalId, headerMessage, onClose }}>
      <figure className="text--center mb-6">
        <img className={styles.externalDataSourceLogo} src={logo} alt={dataSourceName} />
      </figure>
      { introMessage && <p className="text--bold mb-6"><FormattedMessage {...introMessage} /></p> }
      <Form {...{ onSubmit }}>
        <Field id="email" />
        <Field id="password" />
        <Actions {...{ onClose }} />
      </Form>
    </Modal>
  );
};

export default ConnectDataSourceModal;
