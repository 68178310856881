import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import Account from 'modules/Account';
import GlucoseSettings from '../../GlucoseSettings';
import KpiSettings from '../../KpiSettings';
import messages from '../../../messages';
import styles from './PhiSettings.pcss';


const UnlockBtn: FC = () => {
  const hasElevatedPermissions = useSelector(Account.selectors.hasElevatedPermissions);
  const claims = hasElevatedPermissions ? null : Account.constants.ADDITIONAL_CLAIMS.clinicSettings;
  const onSetElevatedPermissionsMode = useAction(Account.actions.setElevatedPermissionsMode, claims);

  return (
    <div className={styles.unlockBtnContainer}>
      <Button
        styleModifier={hasElevatedPermissions ? 'quaternary' : 'primary'}
        className={hasElevatedPermissions ? '' : 'btn--filled'}
        labelMessage={messages.buttons[hasElevatedPermissions ? 'lockEditing' : 'unlockEditing']}
        onClick={onSetElevatedPermissionsMode}
      />
    </div>
  );
};


const PhiSettings: FC = () => {
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const { clinicId } = activeClinicMembership;

  useStyles(styles);

  return (
    <div>
      <UnlockBtn />
      <div className="row">
        <div className="col-6 pr-10"><KpiSettings clinicId={clinicId} /></div>
        <div className="col-6 pl-10"><GlucoseSettings clinicId={clinicId} /></div>
      </div>
    </div>
  );
};

export default PhiSettings;
