import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import useStyles from 'isomorphic-style-loader/useStyles';
import cn from 'classnames';
import get from 'lodash/get';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import ChevronRight from 'svg/chevron-right.svg';
import App from 'modules/App';
import messages from '../../messages';
import styles from './ExternalDataSource.pcss';
import Capabilities from './Capabilities';
import ConnectionStatus from './ConnectionStatus';


interface Props {
  externalDataSource: DataSource,
}


const ExternalDataSource: FC<Props> = ({ externalDataSource }) => {
  const localizationResources = useSelector(App.selectors.localizationResources);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useStyles(styles);

  const { dataSourceProvider, nameKey, logo, capabilities } = externalDataSource;
  const name = get(localizationResources, [nameKey, 'value'], dataSourceProvider);
  const dataSourceInfo = messages.dataSourcesInfos[dataSourceProvider];

  const onToggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className={styles.dataSource}>
      <div className="row">
        <div className="col-auto">
          <Avatar
            avatarImg={logo}
            name={name}
            className={styles.dataSource__logo}
            imgClassName={styles.dataSource__logo__img}
            initialsClassName={styles.dataSource__logo__initials}
          />
        </div>
        <div className="col">
          <div className={`row align-items-center ${styles.dataSource__headerRow}`}>
            <div className="col">
              <h3 className={styles.dataSource__name}>{ name }</h3>
              {
                dataSourceInfo
                  && <p className={styles.dataSource__info}><FormattedMessage {...dataSourceInfo} /></p>
              }
            </div>
            <div className="col-auto d-flex flex-nowrap">
              <ConnectionStatus {...{ externalDataSource }} />
              <Button
                className="collapsible__btn ml-3"
                onClick={onToggleExpand}
              >
                <ChevronRight
                  className={cn('collapsible__btn__icon', { 'collapsible__btn__icon--collapsed': isExpanded })}
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <motion.div
        animate={{ height: isExpanded ? 'auto' : 0 }}
        transition={{ ease: 'easeOut', duration: 0.3 }}
        className={styles.dataSource__capabilities}
      >
        <Capabilities {...{ capabilities }} />
      </motion.div>
    </div>
  );
};

export default ExternalDataSource;
