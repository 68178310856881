import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import withStyles from 'isomorphic-style-loader/withStyles';
import delay from 'lodash/delay';
import find from 'lodash/find';
import get from 'lodash/get';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import Button from 'components/Form/Button';
import intlShape from 'shapes/intlShape';
import Account from 'modules/Account';
import Information from 'modules/Information';
import App from 'modules/App';
import AccountSettings from 'svg/account-settings.svg';
import ChevronRight from 'svg/chevron-right.svg';
import Logout from 'svg/logout.svg';
import Settings from 'svg/settings.svg';
import Swap from 'svg/swap.svg';
import ClinicSettings from 'svg/clinic-settings.svg';
import messages from '../../../../messages';
import * as constants from '../../../../constants';
import styles from './ProfileMenu.pcss';


class ProfileMenu extends React.Component {

  static contextType = AppContext;

  static propTypes = {
    // Implicit props
    account               : Account.shapes.account,
    activeProfile         : PropTypes.shape({ avatar: PropTypes.string }),
    activeProfileType     : Account.shapes.profileType.isRequired,
    activeClinicMembership: Account.shapes.clinicMembership,
    information           : PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName : PropTypes.string.isRequired,
      email    : PropTypes.string.isRequired,
      avatar   : PropTypes.string, // base64
    }),
    isHcpAccount         : PropTypes.bool.isRequired,
    countries            : PropTypes.arrayOf(App.shapes.country),
    openDropdownId       : PropTypes.string,
    localizationResources: PropTypes.object,
    featureToggles       : PropTypes.arrayOf(PropTypes.string),
    intl                 : intlShape.isRequired,
    // Implicit actions
    onEditAccount        : PropTypes.func,
    onSwitchProfile      : PropTypes.func,
    onOpenDropdown       : PropTypes.func,
    onCloseDropdown      : PropTypes.func,
    onSignOut            : PropTypes.func,
  };


  async onOpenMenu(evt) {
    evt.preventDefault();
    const isOpenMenu = this.props.openDropdownId === constants.TOPBAR_AVATAR_DROPDOWN;
    await this.props.onCloseDropdown();
    if (!isOpenMenu) {
      this.props.onOpenDropdown(constants.TOPBAR_AVATAR_DROPDOWN);
    }
  }


  async onSignOut() {
    await this.props.onCloseDropdown();
    delay(this.props.onSignOut, 150); // wait for animation
  }


  get isActiveClinic() {
    const { activeClinicMembership } = this.props;
    return !!activeClinicMembership;
  }


  get activeClinicMembershipSettingsHref() {
    const clinic = get(this.props, 'activeClinicMembership.clinic', {});
    const { organizationUID } = clinic;
    const clinicSlug = getSlug(clinic.name);
    const clinicSettingHref = this.context.getUrl('clinic-settings', { clinicSlug, organizationUID });
    return clinicSettingHref;
  }


  get mainRouteHref() {
    const { isHcpAccount } = this.props;
    if (isHcpAccount) {
      const clinic = get(this.props, 'activeClinicMembership.clinic', {});
      const { organizationUID } = clinic;
      const clinicSlug = getSlug(clinic.name);
      const clinicSettingHref = this.context.getUrl('general-population', { clinicSlug, organizationUID });
      return clinicSettingHref;
    }

    return this.context.getUrl('my-results');
  }


  get countryName() {
    const { countryCode: alpha2Code } = this.props.account;
    const country = find(this.props.countries, { alpha2Code });
    return get(this.props.localizationResources, [country.nameKey, 'value'], country.englishName);
  }


  get imageFile() {
    const { countryCode } = this.props.account;
    return `${countryCode}.svg`;
  }


  get profileName() {
    const { scope } = this.props.account;
    return this.props.intl.formatMessage(Account.messages.scopes[scope]);
  }


  get isGoToCncVisible() {
    const { account } = this.props;
    return (account.scope === Account.constants.SCOPE_NAMES.PROFESSIONAL && account.countryCode === 'us');
  }


  renderHeader() {
    const { firstName, lastName } = this.props.information || {};
    return (
      <div className={styles.dropdown__profileContainer} data-testid="profile-info">
        { this.renderAvatar() }
        <div className={styles.dropdown__profileContent}>
          <h1 className="brand__paragraph--bold">{ `${firstName} ${lastName}` }</h1>
          <div className="brand__paragraph d-flex align-items-center">
            <figure className={styles.dropdown__flagContainer}>
              <img className={styles.dropdown__flag} src={`/aid/assets/svg/flags/${this.imageFile}`} alt="" />
            </figure>
            <div data-testid="profile-details">{ this.countryName }<span className="text--muted text--normal mx-3">|</span>{ this.profileName }</div>
          </div>
        </div>
      </div>
    );
  }


  renderSwitchProfile() {
    return (
      <li className={styles.dropdownMenu__action}>
        <Button
          id="switch-profile"
          onClick={this.props.onSwitchProfile}
          className={styles.menuItem}
        >
          <Swap />
          <FormattedMessage {...messages.topBar.buttons.switchProfile} />
        </Button>
      </li>
    );
  }

  // TODO change route to profile-settings
  renderProfileSettings() {
    return (
      <li className={styles.dropdownMenu__action}>
        <Button
          id="profile-settings"
          type="link"
          to={this.context.getUrl('account-settings')}
          className={styles.menuItem}
        >
          <Settings />
          <FormattedMessage {...messages.topBar.buttons.profileSettings} />
        </Button>
      </li>
    );
  }


  renderClinicSettings() {
    if (!this.isActiveClinic) {
      return null;
    }
    return (
      <li className={styles.dropdownMenu__action}>
        <Button
          id="clinic-settings"
          type="link"
          to={this.activeClinicMembershipSettingsHref}
          className={styles.menuItem}
        >
          <ClinicSettings />
          <FormattedMessage {...messages.topBar.buttons.clinicSettings} />
        </Button>
      </li>
    );
  }


  renderAccountSettings() {
    return (
      <li className={styles.dropdownMenu__action}>
        <Button
          id="account-settings"
          className={styles.menuItem}
          onClick={this.props.onEditAccount}
        >
          <AccountSettings />
          <FormattedMessage {...messages.topBar.buttons.accountSettings} />
        </Button>
      </li>
    );
  }


  renderFeatureToggle() {
    if (!this.props.featureToggles.includes(App.constants.FEATURE_TOGGLES.featurePlatforms)) {
      return null;
    }
    return (
      <>
        <div className={styles.dropdown__separator} />
        <div className={cn('brand__caption--bold', styles.dropdown__header)}>
          <FormattedMessage {...messages.topBar.headers.yourApplications} />
        </div>
        <ul className="dropdownMenu__actions">
          <li className={styles.dropdownMenu__action}>
            <Button
              type="link"
              to={this.mainRouteHref}
              className={styles.menuItem}
            >
              <div className={styles.dropdown__activeDot} />
              <img src="/assets/svg/gc_logo.svg" alt="" />
              <FormattedMessage {...messages.topBar.buttons.gc} />
            </Button>
          </li>
          <li className={styles.dropdownMenu__action}>
            <Button
              type="link"
              to={this.context.bls.cnc}
              className={styles.menuItem}
            >
              <img src="/assets/svg/cnc_logo.svg" alt="" />
              <FormattedMessage {...messages.topBar.buttons.cnc} />
            </Button>
          </li>
        </ul>
      </>
    );
  }


  renderLogout() {
    return (
      <>
        <div className={styles.dropdown__separator} />
        <ul className="dropdownMenu__actions">
          <li className={styles.dropdownMenu__action}>
            <Button
              id="logout-btn"
              onClick={() => this.onSignOut()}
              className={styles.menuItem}
            >
              <Logout />
              <FormattedMessage {...messages.topBar.buttons.logout} />
            </Button>
          </li>
        </ul>
      </>
    );
  }


  renderGoToCnc() {
    if (!this.isGoToCncVisible) {
      return null;
    }
    return (
      <li className={styles.dropdownMenu__action}>
        <Button
          type="link"
          to={this.context.bls.cnc}
          className={styles.menuItem}
        >
          <img src="/assets/img/cnc.png" alt="Contour Next Concierge" />
          <FormattedMessage {...messages.topBar.buttons.cnc} />
        </Button>
      </li>
    );
  }


  renderMenu() {
    if (this.props.openDropdownId !== constants.TOPBAR_AVATAR_DROPDOWN) {
      return null;
    }
    return (
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className={styles.dropdown}
        data-testid="profile-menu-opened"
      >
        { this.renderHeader() }
        <ul className="dropdownMenu__actions">
          { this.renderSwitchProfile() }
          { this.renderProfileSettings() }
          { this.renderAccountSettings() }
          { this.renderClinicSettings() }
          { this.renderGoToCnc() }
        </ul>
        { this.renderFeatureToggle() }
        { this.renderLogout() }
      </motion.div>
    );
  }


  renderAvatar() {
    const { firstName, lastName, avatar } = this.props.information || {};
    return (
      <Avatar
        avatarImg={avatar}
        name={[firstName, lastName]}
        className={styles.avatar}
        imgClassName={styles.avatar__img}
        initialsClassName={styles.avatar__initials}
      />
    );
  }


  render() {
    return (
      <div className={styles.container} data-testid="profile-menu">
        <Link to="" className={styles.profileMenu} onClick={(evt) => this.onOpenMenu(evt)}>
          { this.renderAvatar() }
          <ChevronRight />
        </Link>
        <AnimatePresence>{ this.renderMenu() }</AnimatePresence>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  account               : Account.selectors.account(state),
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
  isHcpAccount          : Account.selectors.isHcpAccount(state),
  activeProfile         : Account.selectors.activeProfile(state),
  activeProfileType     : Account.selectors.activeProfileType(state),
  information           : Information.selectors.information(state),
  countries             : App.selectors.countries(state),
  openDropdownId        : App.selectors.dropdown(state),
  localizationResources : App.selectors.localizationResources(state),
  featureToggles        : App.selectors.featureToggles(state),
});


const mapDispatchToProps = (dispatch) => ({
  onOpenDropdown : (dropdownId) => dispatch(App.actions.openDropdown(dropdownId)),
  onCloseDropdown: () => dispatch(App.actions.closeDropdown()),
  onEditAccount  : () => dispatch(Account.actions.editAidAccount()),
  onSwitchProfile: () => dispatch(Account.actions.switchProfile()),
  onSignOut      : () => dispatch(Account.actions.signOut()),
});


const ConnectedProfileMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ProfileMenu));


export default withStyles(styles)(ConnectedProfileMenu);
