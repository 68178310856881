import React, { FC, useEffect } from 'react';
import { MessageDescriptor } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import messages from '../../../messages';
import Actions from './Actions';
import Indicator from './Indicator';
import styles from './Merging.pcss';


interface Props {
  setHeaderMessage: (message: MessageDescriptor) => void,
}


const Merging: FC<Props> = ({ setHeaderMessage }) => {
  useStyles(styles);

  useEffect(() => {
    setHeaderMessage(messages.headers.mergingPatient);
    return () => {
      setHeaderMessage(messages.headers.mergePatients);
    };
  }, []);

  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <div className="my-4">
          <Indicator />
        </div>
      </div>
      <Actions />
    </div>
  );
};

export default Merging;
