import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MessageDescriptor } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { Toaster } from 'react-hot-toast';
import cn from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import throttle from 'lodash/throttle';
import { useAction } from 'hooks';
import App from 'modules/App';
import Account from 'modules/Account';
import DataSources from 'modules/DataSources';
import Notifications from 'modules/Notifications';
import * as actions from '../../actions';
import { useIsPinnedMenu } from '../../hooks';
import messages from '../../messages';
import styles from '../../components/MainLayout/MainLayout.pcss';


interface Props {
  metaTitleMessage?: MessageDescriptor,
  metaDescriptionMessage?: MessageDescriptor,
  activeVisit?: Visit,
}


const MainLayout: FC<Props> = ({ metaTitleMessage, metaDescriptionMessage, activeVisit, children }) => {
  const isPinnedMenu = useIsPinnedMenu(activeVisit);
  const isPrintMode = useSelector(App.selectors.printMode);
  const countrySettings = useSelector(Account.selectors.countrySettings);
  const devices = useSelector(App.selectors.devices);
  const dataSources = useSelector(DataSources.selectors.dataSources());

  const setMenuOpen = useAction(actions.setMenuOpen);
  const setSelectedMenuItem = useAction(actions.setSelectedMenuItem);
  const setWindowWidth = useAction(actions.setWindowWidth);
  const closeDropdown = useAction(App.actions.closeDropdown);
  const fetchDevices = useAction(App.actions.fetchDevices);
  const validateAccount = useAction(Account.actions.validateAccount);
  const fetchCountrySettings = useAction(Account.actions.fetchCountrySettings);
  const fetchDataSources = useAction(DataSources.actions.fetchDataSources);

  const titleMessage = metaTitleMessage || messages.meta.title;
  const descriptionMessage = metaDescriptionMessage || messages.meta.description;

  const updateScreenWidth = (shouldSetBaseMenuOpen) => {
    if (!process.env.BROWSER) {
      return;
    }
    setWindowWidth(window.innerWidth, shouldSetBaseMenuOpen === true);
  };

  const onUpdateScreenWidth = throttle((shouldSetBaseMenuOpen) => updateScreenWidth(shouldSetBaseMenuOpen), 300);

  useEffect(() => {
    validateAccount();
    if (!countrySettings) fetchCountrySettings();
    if (!devices.length) fetchDevices();
    if (!dataSources.length) fetchDataSources();

    window.addEventListener('resize', onUpdateScreenWidth);
    onUpdateScreenWidth(true);

    return () => {
      window.removeEventListener('resize', onUpdateScreenWidth);
    };
  }, []);

  useStyles(styles);

  const closeAll = () => {
    closeDropdown();
    if (!isPinnedMenu) {
      setMenuOpen(false);
    }
    setSelectedMenuItem(null);
  };

  const onKeyDown = (evt) => {
    // If ESC
    if (evt.keyCode === 27) {
      closeAll();
    }
  };

  return (
    <App.components.LanguageProvider>
      <div
        className={
          cn({
            [styles.root] : !isPrintMode,
            [styles.print]: isPrintMode,
            print         : isPrintMode,
          })
        }
        onClick={closeAll}
        onKeyDown={onKeyDown}
      >
        <App.components.IntlHelmet
          titleMessage={titleMessage}
          descriptionMessage={descriptionMessage}
        />
        <App.components.Cookies />
        { children }
        <Notifications.partials.NotificationsSettingsModal />
        <Notifications.partials.NotificationsHistoryModal />
        {
          process.env.BROWSER
          && (
            <>
              <ReactTooltip
                id="globalTooltip"
                place="bottom"
                type="dark"
                effect="solid"
                className="tooltip"
              />
              <Toaster position="top-right" />
            </>
          )
        }
      </div>
    </App.components.LanguageProvider>
  );
};

export default MainLayout;
