import findIndex from 'lodash/findIndex';
import { registerAction, unregisterAction } from 'helpers/reducerTools';
import { SIGN_OUT_SUCCESS } from 'modules/Account/actionTypes';
import * as actionTypes from './actionTypes';
import * as Tps from './types';


const initialState: Tps.ModuleState = {
  dataSources         : [],
  connectedDataSources: null, // nullable [],
  fetching            : [],
  sending             : [],
  errors              : [],
};


export default function reducer(state: Tps.ModuleState = { ...initialState }, action: Tps.ModuleAction = null) {

  switch (action.type) {

    case actionTypes.FETCH_DATA_SOURCES: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_DATA_SOURCES),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_DATA_SOURCES),
      };
    }

    case actionTypes.FETCH_DATA_SOURCES_SUCCESS: {
      const { dataSources } = action.payload;
      return {
        ...state,
        dataSources,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_DATA_SOURCES),
      };
    }

    case actionTypes.FETCH_DATA_SOURCES_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_DATA_SOURCES),
        errors  : registerAction(state.errors, actionTypes.FETCH_DATA_SOURCES),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.CONNECT: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.CONNECT),
        errors : unregisterAction(state.errors, actionTypes.CONNECT),
      };
    }
    case actionTypes.CONNECT_SUCCESS: {
      const { connectedDataSource } = action.payload;
      return {
        ...state,
        connectedDataSources: [...state.connectedDataSources, connectedDataSource],
        sending             : unregisterAction(state.sending, actionTypes.CONNECT),
      };
    }
    case actionTypes.CONNECT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.CONNECT),
        errors : registerAction(state.errors, actionTypes.CONNECT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    // case actionTypes.DISCONNECT: {
    //   return {
    //     ...state,
    //     sending: registerAction(state.sending, actionTypes.DISCONNECT),
    //     errors : unregisterAction(state.errors, actionTypes.DISCONNECT),
    //   };
    // }
    // case actionTypes.DISCONNECT_SUCCESS: {
    //   const { accountExternalDataSourceId } = action.payload;
    //   return {
    //     ...state,
    //     connectedDataSources: reject(state.connectedDataSources, { accountExternalDataSourceId }),
    //     sending             : unregisterAction(state.sending, actionTypes.DISCONNECT),
    //   };
    // }
    // case actionTypes.DISCONNECT_ERROR: {
    //   return {
    //     ...state,
    //     sending: unregisterAction(state.sending, actionTypes.DISCONNECT),
    //     errors : registerAction(state.errors, actionTypes.DISCONNECT),
    //   };
    // }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_CONNECTED_DATA_SOURCES: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_CONNECTED_DATA_SOURCES),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_CONNECTED_DATA_SOURCES),
      };
    }

    case actionTypes.FETCH_CONNECTED_DATA_SOURCES_SUCCESS: {
      const { connectedDataSources } = action.payload;
      return {
        ...state,
        connectedDataSources,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CONNECTED_DATA_SOURCES),
      };
    }

    case actionTypes.FETCH_CONNECTED_DATA_SOURCES_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_CONNECTED_DATA_SOURCES),
        errors  : registerAction(state.errors, actionTypes.FETCH_CONNECTED_DATA_SOURCES),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN_SUCCESS: {
      const { externalDataSourceId, encryptedDataSourceExchangeToken } = action.payload;
      const connectedDataSources = [...state.connectedDataSources];
      const idx = findIndex(connectedDataSources, { externalDataSourceId });
      if (idx < 0) {
        return state;
      }
      connectedDataSources[idx] = { ...connectedDataSources[idx], encryptedDataSourceExchangeToken };
      return {
        ...state,
        connectedDataSources,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SYNC: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.SYNC),
        errors : unregisterAction(state.errors, actionTypes.SYNC),
      };
    }

    case actionTypes.SYNC_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SYNC),
      };
    }

    case actionTypes.SYNC_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SYNC),
        errors : registerAction(state.errors, actionTypes.SYNC),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.STORE_READINGS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.STORE_READINGS),
        errors : unregisterAction(state.errors, actionTypes.STORE_READINGS),
      };
    }

    case actionTypes.STORE_READINGS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_READINGS),
      };
    }

    case actionTypes.STORE_READINGS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.STORE_READINGS),
        errors : registerAction(state.errors, actionTypes.STORE_READINGS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    // TODO: Remove if confirmed

    // case actionTypes.STORE_HEALTH_DATA: {
    //   return {
    //     ...state,
    //     sending: registerAction(state.sending, actionTypes.STORE_HEALTH_DATA),
    //     errors : unregisterAction(state.errors, actionTypes.STORE_HEALTH_DATA),
    //   };
    // }
    // case actionTypes.STORE_HEALTH_DATA_SUCCESS: {
    //   return {
    //     ...state,
    //     sending: unregisterAction(state.sending, actionTypes.STORE_HEALTH_DATA),
    //   };
    // }
    // case actionTypes.STORE_HEALTH_DATA_ERROR: {
    //   return {
    //     ...state,
    //     sending: unregisterAction(state.sending, actionTypes.STORE_HEALTH_DATA),
    //     errors : registerAction(state.errors, actionTypes.STORE_HEALTH_DATA),
    //   };
    // }

    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT_SUCCESS: {
      return {
        ...state,
        tokens : null,
        profile: null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default:
      return state;

  }
}
