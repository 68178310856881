import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Form/Button';
import { useAction } from 'hooks';
import App from 'modules/App';
import * as selectors from '../../../selectors';


const Actions: FC = () => {
  const isInProgress = useSelector(selectors.isMergePatientInProgress);
  const onCloseModal = useAction(App.actions.closeModal);

  return (
    <div className="modal__actions modal__actions--fixHeight">
      {
        !isInProgress
          ? (
            <Button
              styleModifier="primary"
              labelMessage={App.messages.buttons.close}
              className="btn--block btn--filled"
              onClick={onCloseModal}
            />
          )
          : null
      }
    </div>
  );
};

export default Actions;
