import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import get from 'lodash/get';
import Avatar from 'components/Avatar';
import App from 'modules/App';
import { DATA_SOURCES_TYPE_NAME } from '../constants';
import * as selectors from '../selectors';
import messages from '../messages';
import styles from './ExternalDataSource/ExternalDataSource.pcss';


const ContourCloudInfo: FC = () => {
  const externalDataSources = useSelector(selectors.dataSources('Personal'));
  const localizationResources = useSelector(App.selectors.localizationResources);
  useStyles(styles);
  const ccDataSource: DataSource = externalDataSources.find(
    (eds) => eds.dataSourceProvider === DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD,
  );
  if (!ccDataSource) return null;
  const { dataSourceProvider, nameKey, logo } = ccDataSource;
  const name = get(localizationResources, [nameKey, 'value'], dataSourceProvider);

  return (
    <div className={`${styles.dataSource} ${styles['dataSource--standalone']}`}>
      <div className="row">
        <div className="col-auto">
          <Avatar
            avatarImg={logo}
            name={name}
            className={styles.dataSource__logo}
            imgClassName={styles.dataSource__logo__img}
            initialsClassName={styles.dataSource__logo__initials}
          />
        </div>
        <div className="col">
          <div className={`row align-items-center ${styles.dataSource__headerRow}`}>
            <div className="col">
              <h3 className={styles.dataSource__name}>{ name }</h3>
              <p className={styles.dataSource__info}>
                <FormattedMessage {...messages.dataSourcesInfos.ContourCloudDefault} />
              </p>
            </div>
            <div className="col-auto d-flex flex-nowrap">
              <div className="tag tag--success"><FormattedMessage {...messages.tags.connected} /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContourCloudInfo;
