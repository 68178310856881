export const MODULE_ID = 'dataSources';

export const DATA_SOURCES_TYPE_NAME = {
  CONTOUR_CLOUD: 'ContourCloud',
};

export const CONNECT_DATA_SOURCE_MODAL = `${MODULE_ID}/connectDataSourceModal`;

export const CONNECT_DATA_SOURCE_FORM = `${MODULE_ID}.connectDataSourceForm`;

