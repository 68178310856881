import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import Link from 'components/Link';
import App from 'modules/App';
import CloudDrive from 'modules/CloudDrive';
import Hcp from 'modules/Hcp';
import HcpExports from 'modules/HcpExports';
import Meter from 'modules/Meter';
import Visit from 'modules/Visit';
import * as constants from '../../../constants';
import TopBar from '../../../partials/TopBar';
import ActivePatientOptions from '../TopBar/ActivePatientOptions';
import ActiveClinicMembershipBtn from '../TopBar/ActiveClinicMembershipBtn';
import EnrollCodeBtn from '../TopBar/EnrollCodeBtn';
import ProfileMenu from '../TopBar/ProfileMenu';
import Notifications from '../TopBar/Notifications';
import styles from '../TopBar/TopBar.pcss';


const DownloadMeter: FC = () => {
  const isEnrollPatientInProgress = useSelector(Hcp.selectors.isEnrollPatientInProgress);
  const isMergePatientInProgress = useSelector(Hcp.selectors.isMergePatientInProgress);
  const isStartPatientVisitInProgress = useSelector(Hcp.selectors.isStartPatientVisitInProgress);
  const isEndPatientVisitInProgress = useSelector(Hcp.selectors.isEndPatientVisitInProgress);
  const isSyncInProgress = useSelector(Hcp.selectors.isSyncInProgress);
  const isFetchPhiSetInProgress = useSelector(CloudDrive.selectors.isFetchPhiSetInProgress);
  const isFetchReadingsInProgress = useSelector(CloudDrive.selectors.isFetchReadingsInProgress);
  const isDisabled = isEnrollPatientInProgress || isMergePatientInProgress || isFetchPhiSetInProgress
    || isFetchReadingsInProgress || isStartPatientVisitInProgress || isEndPatientVisitInProgress || isSyncInProgress;

  return (
    <div className={styles.element}>
      <Meter.partials.DownloadDataFromMeterBtn
        className="btn--block btn--filled"
        isDisabled={isDisabled}
      />
    </div>
  );
};


const PatientListOptions: FC = () => {
  const route = useSelector(App.selectors.route);
  if (!route || route.name !== 'patients-list') {
    return null;
  }
  return <HcpExports.components.ExportDataMenu isInProgress={false} />;
};


const HcpTopBar: FC = () => {
  const intl = useIntl();
  const activeVisit = useSelector(Visit.selectors.activeVisit);
  const isDisabled = !!activeVisit;
  const tooltipText = activeVisit ? intl.formatMessage(App.messages.infos.menuDisabledIfActiveVisit) : '';
  useStyles(styles);

  return (
    <TopBar>
      <div className="d-flex align-items-center">
        <div
          className={cn(styles.element, { [styles['element--isDisabled']]: isDisabled })}
          data-for="globalTooltip"
          data-tip={tooltipText}
        >
          <Link to={activeVisit ? '' : '/'}>
            <img src="/assets/img/gco-logo.png" className={styles.logo} alt="GlucoContro.online" />
          </Link>
        </div>
        <div
          className={cn(styles.element, { [styles['element--isDisabled']]: isDisabled })}
          data-for="globalTooltip"
          data-tip={tooltipText}
        >
          <ActiveClinicMembershipBtn />
        </div>
        <div className={styles.element}>
          <EnrollCodeBtn />
        </div>
        <DownloadMeter />
      </div>
      <div
        className={
          cn(styles.element, styles.search, 'd-flex align-items-center', {
            [styles['element--isDisabled']]: isDisabled,
          })
        }
        data-for="globalTooltip"
        data-tip={tooltipText}
      >
        <Hcp.partials.SearchPatients
          forId="topBar"
          formName={constants.TOPBAR_SEARCH_FORM}
          clearOnBlur
        />
        <Hcp.partials.SearchPatientsList id="topBar" className={styles.searchModal} />
      </div>
      <div className={cn(styles.element, 'd-flex align-items-center')}>
        <ActivePatientOptions />
        <PatientListOptions />
        <div className={cn(styles.separate)} />
        <div
          className={cn({ [styles['element--isDisabled']]: isDisabled })}
          data-for="globalTooltip"
          data-tip={tooltipText}
        >
          <Notifications />
        </div>
        <div
          className={cn({ [styles['element--isDisabled']]: isDisabled })}
          data-for="globalTooltip"
          data-tip={tooltipText}
        >
          <ProfileMenu />
        </div>
      </div>
    </TopBar>
  );
};

export default HcpTopBar;
