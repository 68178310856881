import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import App from 'modules/App';
import Account from 'modules/Account';
import { CONNECT_DATA_SOURCE_FORM } from '../../constants';


interface Props {
  id: string,
}


const Field: FC<Props> = ({ id }) => {
  const formValues = useSelector(App.selectors.formSelector(CONNECT_DATA_SOURCE_FORM));
  const onSetFormValue = useAction(App.actions.setFormValue, CONNECT_DATA_SOURCE_FORM);

  return (
    <FormGroup
      id={id}
      labelMessage={Account.messages.labels[id]}
      formValues={formValues}
    >
      <Input
        type={id === 'password' ? 'password' : 'text'}
        placeholder={Account.messages.placeholders[id]}
        onChange={onSetFormValue}
      />
    </FormGroup>
  );
};

export default memo(Field);
