// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1KDuy{padding:1.6rem 2.4rem;background:#f5f9ff;border-radius:8px}.PJ8j7{margin-bottom:.8rem}.PJ8j7:last-child{margin-bottom:0}._54kzk{font-size:12px;font-size:1.2rem;color:#95a1bd;line-height:1.33}.Wa5kT{font-weight:700;line-height:1.5}._1jahp{color:#6f7ea2}._2D6iS{position:relative}._10NOq{position:absolute;top:44px;width:100%;background:#fff;padding:.8rem 1.6rem;border-radius:8px;z-index:1030;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"patientData": "_1KDuy",
	"patientData__entry": "PJ8j7",
	"patientData__label": "_54kzk",
	"patientData__value": "Wa5kT",
	"patientData__value--placeholder": "_1jahp",
	"searchContainer": "_2D6iS",
	"foundPatients": "_10NOq"
};
module.exports = exports;
