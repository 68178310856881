import React, { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import messages from '../../messages';
import styles from './ExternalDataSourcesIntro.pcss';


interface Props {
  className?: string,
}


const ExternalDataSourcesIntro: FC<Props> = ({ className }) => {
  useStyles(styles);

  return (
    <p className={cn(styles.dataSourcesIntro, className)}>
      <FormattedMessage {...messages.intros.dataSourcesSettings} />
    </p>
  );
};

export default memo(ExternalDataSourcesIntro);
