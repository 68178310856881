export const MODULE_ID = 'clinicManagement';

export const LICENCE_LENGTH = 12;
export const LICENCE_PART_LENGTH = 4;

export const CLINIC_MEMBERSHIP_DROPDOWN = `${MODULE_ID}/clinicMembershipDropdown`;

export const IMPORT_PATIENT_DATABASE_MODAL = `${MODULE_ID}/importPatientDatabaseModal`;

export const CLINIC_SETTINGS_FORM = `${MODULE_ID}.clinicSettingsForm`;
export const CLINIC_GLUCOSE_SETTINGS_FORM = `${MODULE_ID}.clinicGlucoseSettingsForm`;
export const CREATE_CLINIC_FORM = `${MODULE_ID}.createClinicForm`;
export const SEARCH_CLINIC_FORM = `${MODULE_ID}.searchClinicForm`;
export const INVITE_HCP_FORM = `${MODULE_ID}.inviteHCPForm`;
export const CLINIC_KPI_SETTINGS_FORM = `${MODULE_ID}.clinicKpiSettingsForm`;

export const CLINIC_GLUCOSE_SETTINGS_WIDGET = `${MODULE_ID}.clinicGlucoseSettingsWidget`;
