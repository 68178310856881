import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import App from 'modules/App';
import Account from 'modules/Account';
import * as constants from '../../../constants';
import messages from '../../../messages';


const ImportPatientDatabase: FC = () => {
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const isAdmin = get(activeClinicMembership, 'isAdmin', false);

  const onOpenModal = useAction(App.actions.openModal, constants.IMPORT_PATIENT_DATABASE_MODAL);

  if (!isAdmin) {
    return null;
  }

  return (
    <section>
      <h2 className="section__header"><FormattedMessage {...messages.headers.importPatientDatabase} /></h2>
      <p className="section__intro"><FormattedMessage {...messages.intros.importPatientDatabase} /></p>
      <Button
        styleModifier="primary"
        labelMessage={messages.buttons.import}
        className="btn--filled"
        onClick={onOpenModal}
      />
    </section>
  );
};

export default ImportPatientDatabase;
