import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import { useAction, useProgressSuccess } from 'hooks';
import Alert from 'components/Alert';
import App from 'modules/App';
import Account from 'modules/Account';
import history from 'helpers/history';
import messages from 'modules/Hcp/messages';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import * as actions from '../../actions';


interface Props {
  activePatient: PatientRecord,
  patients: PatientRecord[],
}


const PatientToMergeAlert: FC<Props> = ({ activePatient, patients }) => {
  const { getUrl } = useContext(AppContext);

  const dismissedAlerts = useSelector(App.selectors.dismissedAlerts);
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const isRemoveInProgress = useSelector(selectors.isRemovePatientInProgress);
  const hasRemoveErrors = useSelector(selectors.hasRemovePatientErrors);

  const dismissAlert = useAction(App.actions.dismissAlert);
  const openModal = useAction(App.actions.openModal);
  const setFormContext = useAction(App.actions.setFormContext);
  const updatePatientProfile = useAction(actions.updatePatient);

  const onNavigate = () => {
    const { organizationUID, name } = get(activeClinicMembership, 'clinic', {});
    const url = getUrl('patients-list', { clinicSlug: getSlug(name), organizationUID });
    history.push(url);
  };

  useProgressSuccess(isRemoveInProgress, hasRemoveErrors, onNavigate);

  if (
    !activePatient
    || activePatient.isExplicitBannerDiscarded
    || !activePatient.createTimestamp
    || !isEmpty(activePatient.devicesSerialNumberTokens)
    || activePatient.source !== App.constants.PATIENTS_SOURCES.ENROLL_CODE
  ) {
    return null;
  }

  const alertName = `PatientToMergeAlert-${activePatient.id}`;

  if (dismissedAlerts.includes(alertName)) {
    return null;
  }

  const { createTimestamp } = activePatient;
  const nowTimestamp = +moment().locale('en--account').format('X');
  const expireDiff = 7 * 86400;
  if (nowTimestamp - createTimestamp > expireDiff) {
    return null;
  }


  const onDismiss = () => {
    dismissAlert({ message: alertName });
  };

  const onKeepPatient = () => {
    updatePatientProfile(activePatient, { isExplicitBannerDiscarded: true }, activeClinicMembership);
  };

  const onMergePatient = () => {
    openModal(constants.MERGE_PATIENT_MODAL);
  };

  const onRemovePatient = () => {
    setFormContext(constants.REMOVE_PATIENT_FORM, activePatient);
    openModal(constants.REMOVE_PATIENT_MODAL);
  };

  const alertActions = [
    {
      action : onRemovePatient,
      message: messages.buttons.removePatient,
    },
    {
      action : onKeepPatient,
      message: messages.buttons.keepPatient,
    },
  ];
  if (patients && patients.length) {
    alertActions.splice(1, 0, {
      action : onMergePatient,
      message: messages.buttons.mergePatient,
    });
  }

  return (
    <Alert
      id="enrolledPatientWarning"
      type="warning"
      message={messages.alerts.enrolledPatient}
      actions={alertActions}
      onClose={onDismiss}
    />
  );

};

export default PatientToMergeAlert;
