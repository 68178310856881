import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useAction, useProgressSuccess } from 'hooks';
import Modal from 'components/Modal';
import App from 'modules/App';
import { REMOVE_PATIENT_MODAL } from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from '../ModalCommon.pcss';
import Actions from './Actions';
import Person from './Person';


const RemovePatientModal: FC = () => {
  const openModalId = useSelector(App.selectors.modal);
  const isInProgress = useSelector(selectors.isRemovePatientInProgress);
  const hasErrors = useSelector(selectors.hasRemovePatientErrors);

  const onCloseModal = useAction(App.actions.closeModal);

  useProgressSuccess(isInProgress, hasErrors, onCloseModal);
  useStyles(styles);

  return (
    <Modal
      modalId={REMOVE_PATIENT_MODAL}
      openModalId={openModalId}
      styleModifier="md"
      headerMessage={messages.headers.removePatient}
      isInProgress={isInProgress}
      onClose={onCloseModal}
    >
      <div className={styles.container}>
        <Person />
        <FormattedMessage {...messages.infos.areYouSureToRemovePatient} />
      </div>
      <Actions />
    </Modal>
  );
};

export default RemovePatientModal;
