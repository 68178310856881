import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import upperFirst from 'lodash/upperFirst';
import { calculateGlucoseConcentrationLevels, calculateGlucoseLevels } from 'helpers/kpi';
import ActiveNotingLabel from 'components/ActiveNotingLabel';
import Widget from 'components/Widget';
import App from 'modules/App';
import * as constants from '../../constants';
import messages from '../../messages';
import styles from './GlucoseConcentrationLevelsWidget.pcss';


class GlucoseConcentrationLevelsWidget extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { readings, conversion, standards } = props;
    const glucoseConcentrationLevels = calculateGlucoseConcentrationLevels(standards, conversion);
    const glucoseLevels = calculateGlucoseLevels(readings, conversion, glucoseConcentrationLevels);

    if ((readings && readings !== state.readings)
      || !isEqual(glucoseConcentrationLevels, state.glucoseConcentrationLevels)
    ) {
      return {
        glucoseConcentrationLevels,
        glucoseLevels,
        readings,
      };
    }

    return null;
  }

  static propTypes = {
    // Explicit props
    isInProgress              : PropTypes.bool,
    size                      : PropTypes.number,
    glucoseLevels             : PropTypes.array,
    glucoseConcentrationLevels: PropTypes.array,
    conversion                : PropTypes.object,
    readings                  : PropTypes.array,
    standards                 : PropTypes.object,
    className                 : PropTypes.string,
    // Explicit actions
    onAddNote                 : PropTypes.func,
  };

  static defaultProps = {
    size: 300,
  };


  constructor(props) {
    super(props);
    this.state = {
      glucoseLevels: {
        veryHigh: { value: 0, percentage: 20 },
        high    : { value: 0, percentage: 20 },
        target  : { value: 0, percentage: 20 },
        low     : { value: 0, percentage: 20 },
        veryLow : { value: 0, percentage: 20 },
      },
      readings: [],
    };
  }

  onAddLevelNote(evt, level, index) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.props.onAddNote) {
      return;
    }
    const { glucoseLevels } = this.state;
    const noteType = `GlucoseConcentrationLevel${upperFirst(level)}`;
    const value = glucoseLevels[level].percentage;
    const rangeValue = this.renderGlucoseRange(index);
    const titleMessage = messages.glucoseConcentrationLevels.noteTitle;
    this.props.onAddNote(noteType, { value, unitSymbol: '%', rangeValue, status: level, titleMessage });
  }


  get chartColors() {
    const { isInProgress } = this.props;
    const { GLUCOSE_LEVELS_COLORS } = App.constants;

    return isInProgress
      ? ['#D3D3D3', '#E0E8F2', '#D3D3D3', '#E0E8F2', '#D3D3D3']
      : [
        GLUCOSE_LEVELS_COLORS.veryHigh,
        GLUCOSE_LEVELS_COLORS.high,
        GLUCOSE_LEVELS_COLORS.normal,
        GLUCOSE_LEVELS_COLORS.low,
        GLUCOSE_LEVELS_COLORS.veryLow,
      ];
  }


  getHeightStyle(level) {
    const { glucoseLevels } = this.state;
    return { height: `${glucoseLevels[level].percentage}%`, flex: glucoseLevels[level].percentage };
  }


  renderReadingsTotal() {
    const { readings } = this.props;
    return (
      <div className={styles.glucoseConcentrationLevels__totalReadings}>
        <FormattedMessage {...messages.glucoseConcentrationLevels.totalReadings} />
        <span>{ `: ${readings.length}` }</span>
      </div>
    );
  }


  renderGlucoseRange(index) {
    const { conversion } = this.props;
    const { glucoseConcentrationLevels } = this.state;
    if (!glucoseConcentrationLevels) {
      return null;
    }
    if (index < 2) {
      return `>${glucoseConcentrationLevels[index]} ${conversion.unitSymbol}`;
    }
    if (index === 2) {
      return `${glucoseConcentrationLevels[index]} - ${glucoseConcentrationLevels[index - 1]} ${conversion.unitSymbol}`;
    }
    return `<${glucoseConcentrationLevels[index - 1]} ${conversion.unitSymbol}`;

  }


  renderLevels() {
    const { size } = this.props;
    const { glucoseLevels } = this.state;
    return (
      <div className={styles.glucoseConcentrationLevels__levelsContainer} style={{ height: size }}>
        {
          map(Object.keys(glucoseLevels), (level, index) => (
            <a
              key={`barRange-${level}`}
              href=""
              className={cn(styles.glucoseConcentrationLevels__barSegment, { activeNoting: this.props.onAddNote })}
              style={this.getHeightStyle(level)}
              onClick={(evt) => this.onAddLevelNote(evt, level, index)}
            >
              { this.renderGlucoseRange(index) }
            </a>
          ))
        }
      </div>
    );
  }


  renderBar() {
    const { size } = this.props;
    const { glucoseLevels } = this.state;
    const { chartColors } = this;
    return (
      <div className={styles.glucoseConcentrationLevels__barContainer}>
        <div className={styles.glucoseConcentrationLevels__bar} style={{ height: size }}>
          {
            map(Object.keys(glucoseLevels), (level, index) => (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <a
                key={`barSegment-${level}`}
                href=""
                className={cn(styles.glucoseConcentrationLevels__barSegment, { activeNoting: this.props.onAddNote })}
                style={{ ...this.getHeightStyle(level), background: chartColors[index] }}
                onClick={(evt) => this.onAddLevelNote(evt, level, index)}
              />
            ))
          }
        </div>
      </div>
    );
  }


  renderResults() {
    const { size } = this.props;
    const { glucoseLevels } = this.state;
    return (
      <div className={styles.glucoseConcentrationLevels__resultsContainer} style={{ height: size }}>
        {
          map(Object.keys(glucoseLevels), (level, index) => (
            <a
              key={`barResult-${level}`}
              href=""
              className={
                cn(
                  styles.glucoseConcentrationLevels__barSegment,
                  styles.glucoseConcentrationLevels__barSegmentResults,
                  { activeNoting: this.props.onAddNote },
                )
              }
              style={this.getHeightStyle(level)}
              onClick={(evt) => this.onAddLevelNote(evt, level, index)}
            >
              <div className={styles.glucoseConcentrationLevels__resultsPercentage}>
                { `${glucoseLevels[level].percentage}% ` }
                <FormattedMessage {...messages.glucoseConcentrationLevels[level]} />
              </div>
              <div>
                { glucoseLevels[level].value }
              </div>
            </a>
          ))
        }
      </div>
    );
  }


  renderChart() {
    const { isInProgress } = this.props;
    return (
      <div
        className={
          cn(styles.glucoseConcentrationLevels, {
            fadingLoader: isInProgress,
          })
        }
      >
        { this.renderLevels() }
        { this.renderBar() }
        { this.renderResults() }
      </div>
    );
  }


  renderActiveNotingLabel() {
    if (!this.props.onAddNote) {
      return null;
    }
    return <ActiveNotingLabel labelMessage={messages.noting.clickLevel} />;
  }


  render() {
    return (
      <Widget
        widgetId={constants.GLUCOSE_CONCENTRATION_LEVELS_WIDGET}
        key="GlucoseConcentrationLevelsWidget"
        headerMessage={messages.glucoseConcentrationLevels.header}
        className={cn(styles.glucoseConcentrationLevels__widget, this.props.className)}
      >
        { this.renderActiveNotingLabel() }
        <div className={styles.glucoseConcentrationLevels__container}>
          <div className={styles.glucoseConcentrationLevels__content}>
            { this.renderReadingsTotal() }
            { this.renderChart() }
          </div>
        </div>
      </Widget>
    );
  }

}


export default withStyles(styles)(GlucoseConcentrationLevelsWidget);
