import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';


const Actions: FC = () => {
  const formValues = useSelector(App.selectors.formSelector(constants.REMOVE_PATIENT_FORM));
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const patient = get(formValues, 'contextData');
  const isInProgress = useSelector(selectors.isRemovePatientInProgress);

  const closeModal = useAction(App.actions.closeModal);
  const clearForm = useAction(App.actions.clearForm(constants.REMOVE_PATIENT_FORM));
  const onRemovePatient = useAction(actions.removePatient, patient, activeClinicMembership);

  const onClose = () => {
    closeModal();
    clearForm();
  };

  return (
    <div className="modal__actions row">
      <div className="col-6">
        <Button
          styleModifier="quaternary"
          labelMessage={messages.buttons.decline}
          className="btn--block"
          isDisabled={isInProgress}
          onClick={onClose}
        />
      </div>
      <div className="col-6">
        <Button
          styleModifier="secondary"
          labelMessage={messages.buttons.removePatient}
          className="btn--block btn--filled"
          isInProgress={isInProgress}
          onClick={onRemovePatient}
        />
      </div>
    </div>
  );
};

export default Actions;
