import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import { AppContext } from 'context';
import copyTextToClipboardFallback from 'helpers/copyTextToClipboardFallback';
import Button from 'components/Form/Button';
import CopyButton from 'svg/copy.svg';
import Account from 'modules/Account';
import messages from '../../../../messages';
import styles from './EnrollCodeBtn.pcss';


class EnrollCodeBtn extends React.Component {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    styleModifier         : PropTypes.string,
    className             : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // Implicit props
    activeClinicMembership: Account.shapes.clinicMembership,
  };


  static defaultProps = {
    styleModifier: 'transparent-primary',
    className    : 'btn--block btn--filled',
  };


  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      copyTextToClipboardFallback(text);
      return;
    }
    navigator.clipboard.writeText(text);
  }


  render() {
    const enrollCode = get(this.props.activeClinicMembership, 'enrollCode');
    if (!enrollCode) {
      return null;
    }
    return (
      <Button
        id="copyClinicCodeBtn"
        onClick={() => this.copyTextToClipboard(enrollCode)}
        styleModifier={this.props.styleModifier}
        className={cn(styles.root, this.props.className)}
      >
        <div className={styles.label}>
          <FormattedMessage {...messages.topBar.buttons.enroll} />
          <div className={styles.enrollCode} data-testid="enroll-code">
            { enrollCode }
            <CopyButton />
          </div>
        </div>
      </Button>
    );
  }

}


const mapStateToProps = (state) => ({
  activeClinicMembership: Account.selectors.activeClinicMembership(state),
});


const ConnectedEnrollCodeBtn = connect(
  mapStateToProps,
)(EnrollCodeBtn);


export default withStyles(styles)(ConnectedEnrollCodeBtn);
