// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1JfR0{position:relative;width:250px;width:25rem;height:250px;height:25rem;border:4rem solid rgba(48,168,255,.1);border-radius:50%}._1JfR0._1VSLg{border-color:rgba(246,86,99,.1)}._12zDU{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;position:absolute;top:-27.5px;top:-2.75rem;left:-27.5px;left:-2.75rem;width:225px;width:22.5rem;height:225px;height:22.5rem;border:1.5rem solid #30a8ff;border-radius:50%}._1VSLg>._12zDU{border-color:#f65663}._1kF3e{width:80px;width:8rem;height:80px;height:8rem;fill:#30a8ff}._1VSLg ._1kF3e{fill:#f65663}._2PEz6{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:250px;width:25rem;height:251px;height:25.1rem}._2WqT7{width:225px;width:22.5rem;height:225px;height:22.5rem}._3nz5h{width:160px;width:16rem;height:150px;height:15rem;fill:#30a8ff}", ""]);
// Exports
exports.locals = {
	"indicatorBackground": "_1JfR0",
	"error": "_1VSLg",
	"indicator": "_12zDU",
	"indicatorIcon": "_1kF3e",
	"loaderContainer": "_2PEz6",
	"loader": "_2WqT7",
	"loaderIcon": "_3nz5h"
};
module.exports = exports;
