import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { MessageDescriptor } from 'react-intl';
import { useAction } from 'hooks';
import Modal from 'components/Modal';
import App from 'modules/App';
import { MERGE_PATIENT_MODAL, MERGE_PATIENT_FORM } from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import ChoosePatient from './ChoosePatient';
import Merging from './Merging';


const components = {
  ChoosePatient,
  Merging,
};

const defaultComponentName = 'ChoosePatient';
const defaultHeaderMessage = messages.headers.mergePatients;
const modalId = MERGE_PATIENT_MODAL;


const MergePatientsModal: FC = () => {
  const isInProgress = useSelector(selectors.isBindSharingRequestWithClinicPatientInProgress);
  const openModalId = useSelector(App.selectors.modal);

  const closeModal = useAction(App.actions.closeModal);
  const clearForm = useAction(App.actions.clearForm, MERGE_PATIENT_FORM);

  const [componentName, setComponentName] = useState<string>(defaultComponentName);
  const [headerMessage, setHeaderMessage] = useState<MessageDescriptor>(defaultHeaderMessage);

  const Component = components[componentName];
  const styleModifier = componentName === 'ChoosePatient' ? 'lg' : undefined;

  const onClose = () => {
    closeModal();
    clearForm();
    setComponentName(defaultComponentName);
  };

  return (
    <Modal {...{ modalId, styleModifier, openModalId, headerMessage, isInProgress, onClose }}>
      <Component {...{ setComponentName, setHeaderMessage }} />
    </Modal>
  );
};

export default MergePatientsModal;
