import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAction, useProgressSuccess } from 'hooks';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import DataSources from 'modules/DataSources';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import ImportPatientDatabaseModal from '../../ImportPatientDatabaseModal';
import ClinicCloudDrive from './ClinicCloudDrive';
import ImportPatientDatabase from './ImportPatientDatabase';


const AdminDataSettings: FC = () => {
  // TODO: Move activeClinicMembership to ImportPatientDatabaseModal
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const isClinicAdmin = useSelector(Account.selectors.isClinicAdmin);

  if (!isClinicAdmin) {
    return null;
  }

  return (
    <div className="row mb-11">
      <div className="col-6 pr-10">
        <ClinicCloudDrive />
      </div>
      <div className="col-6 pl-10">
        <ImportPatientDatabase />
      </div>
      <ImportPatientDatabaseModal clinicMembership={activeClinicMembership} />
    </div>
  );
};


const DataSettings: FC = () => {
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const authorizationCode = useSelector(CloudDrive.selectors.authorizationCode);
  const isInProgress = useSelector(selectors.isReauthorizeClinicCloudDriveInProgress);
  const hasErrors = useSelector(selectors.hasReauthorizeClinicCloudDriveError);

  const connectToClinic = useAction(Account.actions.connectToClinic, activeClinicMembership);
  const disconnectClinic = useAction(Account.actions.disconnectClinic);
  const reauthorizeClinicCloudDrive = useAction(actions.reauthorizeClinicCloudDrive);

  const onConnect = () => {
    disconnectClinic();
    if (activeClinicMembership.membershipStatus === 'Active') {
      connectToClinic();
    }
  };

  useEffect(() => {
    if (authorizationCode) {
      reauthorizeClinicCloudDrive(authorizationCode, activeClinicMembership);
    } else {
      onConnect();
    }
  }, []);

  useProgressSuccess(isInProgress, hasErrors, onConnect);

  return (
    <div>
      <AdminDataSettings />
      <section>
        <DataSources.partials.ExternalDataSources consumer="Professional" />
      </section>
    </div>
  );
};

export default DataSettings;
