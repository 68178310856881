import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// import { isNotDisconnectableDataSource } from 'helpers/externalDataSources';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import App from 'modules/App';
import { CONNECT_DATA_SOURCE_MODAL, CONNECT_DATA_SOURCE_FORM } from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import styles from './ExternalDataSource.pcss';


interface Props {
  externalDataSource: DataSource,
}


const ConnectionStatus: FC<Props> = ({ externalDataSource }) => {
  const { externalDataSourceId } = externalDataSource;
  const connectedDataSources = useSelector(selectors.connectedDataSources);
  const openConnectDataSourceModal = useAction(App.actions.openModal, CONNECT_DATA_SOURCE_MODAL);
  const setConnectDataSourceFormContext = useAction(App.actions.setFormContext, CONNECT_DATA_SOURCE_FORM);

  if (!connectedDataSources) {
    return null;
  }

  const connectedDataSource = connectedDataSources.find((cds) => cds.externalDataSourceId === externalDataSourceId);
  const isConnected = !!connectedDataSource;

  // if (connectedDataSource) {
  //   const isNotDisconnectable = isNotDisconnectableDataSource(externalDataSource);
  //   return (
  //     <div className={styles.dataSource__status}>
  //       <div className="tag tag--success"><FormattedMessage {...messages.tags.connected} /></div>
  //       <Button
  //         isDisabled={isNotDisconnectable}
  //         styleModifier="quaternary"
  //         labelMessage={messages.buttons.disconnect}
  //         className={cn('btn--sm px-4 mx-3', { 'text--primary': !isNotDisconnectable })}
  //         // isInProgress={isDisconnectInProgress}
  //         // onClick={() => onDisconnect(connectedDataSource.accountExternalDataSourceId)}
  //       />
  //     </div>
  //   );
  // }

  const onConnect = () => {
    setConnectDataSourceFormContext({ externalDataSource });
    openConnectDataSourceModal();
  };

  return (
    <div className={styles.dataSource__status}>
      <div className={`tag tag--${isConnected ? 'success' : 'error'}`}>
        <FormattedMessage {...messages.tags[isConnected ? 'connected' : 'notConnected']} />
      </div>
      <Button
        styleModifier="quaternary"
        labelMessage={messages.buttons[isConnected ? 'reauthorize' : 'connect']}
        className="btn--sm px-4 mx-3 text--primary"
        // isInProgress={isConnectInProgress}
        onClick={onConnect}
      />
    </div>
  );
};

export default ConnectionStatus;
