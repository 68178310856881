import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { normalizeText } from 'helpers/normalizeText';
import * as selectors from './selectors';


export const useFindSimilarPatient = (clinicPatient: PatientRecord): PatientRecord => {
  const clinicPatients: PatientRecord[] = useSelector(selectors.patients);
  const [similarPatient, setSimilarPatient] = useState<PatientRecord>(null);

  useEffect(() => {
    const normalizedName = normalizeText([clinicPatient.lastName, clinicPatient.firstName]);
    let similarPatients = clinicPatients.filter(
      (cp) => cp.id !== clinicPatient.id && normalizeText([cp.lastName, cp.firstName]) === normalizedName,
    );
    if (similarPatients.length > 1) {
      const clinicPatientsByDate = similarPatients.filter((sp) => sp.dateOfBirth === clinicPatient.dateOfBirth);
      if (clinicPatientsByDate.length) {
        similarPatients = clinicPatientsByDate;
      }
    }
    setSimilarPatient(similarPatients.length ? similarPatients[0] : null);
  }, [clinicPatient]);

  return similarPatient;
};


export const useSearchPatients = (
  search: string,
  params?: {
    omitPatient?: PatientRecord,
    maxLength?: number,
    emptyDefault?: boolean,
    sortByFavorites?: boolean,
    orderDesc?: boolean,
  },
) => {
  const {
    omitPatient,
    maxLength = 0,
    emptyDefault = false,
    sortByFavorites = false,
    orderDesc = false,
  } = params || {};
  const clinicPatients: PatientRecord[] = useSelector(selectors.patients);
  let initialFoundPatients = emptyDefault ? [] : [...clinicPatients];
  if (omitPatient && initialFoundPatients.length) {
    initialFoundPatients = initialFoundPatients.filter((p) => p.id !== omitPatient.id);
  }
  const [foundPatients, setFoundPatients] = useState<PatientRecord[]>(initialFoundPatients);

  const sortPatients = (a: PatientRecord, b: PatientRecord): number => {
    let favSort = 0;
    if (sortByFavorites) {
      if (a.isFavorite > b.isFavorite) favSort = -1;
      else if (a.isFavorite < b.isFavorite) favSort = 1;
    }
    return favSort || (
      orderDesc
        ? `${b.lastName} ${b.firstName}`.localeCompare(`${a.lastName} ${a.firstName}`)
        : `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`)
    );
  };

  useEffect(() => {
    let filteredPatients = search
      ? clinicPatients.filter(
        (cp) => (!omitPatient || cp.id !== omitPatient.id)
          && (
            normalizeText([cp.lastName, cp.firstName]).includes(search)
            || normalizeText([cp.firstName, cp.lastName]).includes(search)
          ),
      )
      : initialFoundPatients;

    if (filteredPatients.length) filteredPatients = filteredPatients.sort(sortPatients);
    if (maxLength) filteredPatients = filteredPatients.slice(0, maxLength);
    setFoundPatients(filteredPatients);
  }, [search]);

  return foundPatients;
};
