import React, { FC, MouseEvent, useContext } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import get from 'lodash/get';
import { AppContext } from 'context';
import { getSlug } from 'helpers/urlTools';
import Star from 'svg/star-filled.svg';
import Avatar from 'components/Avatar';
import Link from 'components/Link';
import App from 'modules/App';
import Account from 'modules/Account';
import styles from '../PatientsList.pcss';


interface Props {
  id: string,
  patient: PatientRecord,
  styleModifier?: 'halfSpace',
  onPatientClick: (evt: MouseEvent, patient: PatientRecord) => void,
}


const ActivePatient: FC<Props> = ({ id, patient, styleModifier, onPatientClick }) => {
  const { getUrl } = useContext(AppContext);
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const route = useSelector(App.selectors.route);

  const { avatar, firstName, lastName } = patient;
  const { organizationUID, name } = get(activeClinicMembership, 'clinic', {});
  const clinicSlug = getSlug(name);
  const patientSlug = getSlug(`${patient.firstName} ${patient.lastName}`);
  const { patientId } = route.params;

  const onClick = (evt: MouseEvent) => {
    if (onPatientClick) {
      onPatientClick(evt, patient);
    }
  };

  return (
    <li
      key={patient.id}
      className={
        cn(
          styles.item,
          {
            [styles['item--active']]         : route.name === 'hcp-results' && patientId === patient.id,
            [styles['item--halfSpace']]      : styleModifier === 'halfSpace',
            [styles['item--halfSpaceActive']]: styleModifier === 'halfSpace'
            && route.name === 'hcp-results'
            && patientId === patient.id,
            [styles[`item__${id}`]]        : styles[`item__${id}`],
            [styles[`item__${id}--active`]]: styles[`item__${id}`] && patientId === patient.id,
          },
        )
      }
    >
      <Link
        to={
          getUrl('hcp-results', {
            clinicSlug,
            organizationUID,
            patientSlug,
            patientId: patient.id,
          })
        }
        onClick={onClick}
      >
        <div className="row flex-nowrap align-items-center">
          <div className="col-auto pr-2">
            <Avatar
              avatarImg={avatar}
              name={[firstName, lastName]}
              className={styles.item__avatar}
              imgClassName={styles.item__avatar__img}
              initialsClassName={styles.item__avatar__initials}
            />
            { /* <span className={styles.item__notificationsBadge} /> */ }
          </div>
          <div className="col px-3">
            <p
              data-hj-suppress
              className={
                cn(styles.item__name, {
                  [styles['item__name--withMenu']]: patient.isFavorite,
                  [styles[`item__name--${id}`]]   : styles[`item__name--${id}`],
                })
              }
            >
              { patient.firstName } { patient.lastName }
            </p>
          </div>
          <div className="col-auto pl-2">
            <Star className={cn(styles.star, { [styles.star_active]: patient.isFavorite })} />
          </div>
        </div>
      </Link>
    </li>
  );
};

export default ActivePatient;
