import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import * as shapes from './shapes';
import reducer from './reducer';
import sagas from './sagas';
import messages from './messages';

import PatientToMergeAlert from './components/PatientToMergeAlert';
import DeclineEnrollModal from './components/DeclineEnrollModal';
import InvitePatientModal from './components/InvitePatientModal';
import MergePatientModal from './components/MergePatientModal'; // old
import MergePatientsModal from './components/MergePatientsModal'; // new
import RemovePatientModal from './components/RemovePatientModal';
import ResendInvitationModal from './components/ResendInvitationModal';
import SetEmailAndSendSharingRequestModal from './components/SetEmailAndSendSharingRequestModal';
import SyncDataMismatchModal from './components/SyncDataMismatchModal';

import AddPatientBtn from './partials/AddPatientBtn';
import VisitsNotesBtn from './partials/VisitsNotesBtn';
import StartStopVisitBtn from './partials/StartStopVisitBtn';
import AddPatientForm from './partials/AddPatientForm';
import PatientsList from './partials/PatientsList';
import ActivePatients from './partials/PatientsList/ActivePatients';
import SearchPatients from './partials/SearchPatients';
import SearchPatientsList from './partials/SearchPatientsList';
import SearchClinics from './partials/SearchClinics';


const components = {
  PatientToMergeAlert,
  DeclineEnrollModal,
  MergePatientModal,
  MergePatientsModal,
  InvitePatientModal,
  RemovePatientModal,
  ResendInvitationModal,
  SetEmailAndSendSharingRequestModal,
  SyncDataMismatchModal,
};


const partials = {
  ActivePatients,
  AddPatientBtn,
  VisitsNotesBtn,
  StartStopVisitBtn,
  AddPatientForm,
  PatientsList,
  SearchPatients,
  SearchPatientsList,
  SearchClinics,
};


export default {
  actionTypes,
  actions,
  components,
  constants,
  partials,
  reducer,
  selectors,
  sagas,
  shapes,
  messages,
};
