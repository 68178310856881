// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3HsSK{display:block;position:relative;max-width:430px}.mq4_q .wtejs{height:36px;height:3.6rem;line-height:1;font-size:12px;font-size:1.2rem;background:#f5f9ff;border:none;border-radius:8px}html[dir=ltr] .mq4_q .wtejs{padding-right:5rem}html[dir=rtl] .mq4_q .wtejs{padding-left:5rem}.mq4_q .wtejs::-webkit-input-placeholder{color:#6f7ea2}.mq4_q .wtejs::-moz-placeholder{color:#6f7ea2}.mq4_q .wtejs:-ms-input-placeholder{color:#6f7ea2}.mq4_q .wtejs::-ms-input-placeholder{color:#6f7ea2}.mq4_q .wtejs::placeholder{color:#6f7ea2}._7w0nl{position:absolute;top:50%;width:14px;width:1.4rem;height:14px;height:1.4rem;margin-top:-.7rem;fill:#6f7ea2}html[dir=ltr] ._7w0nl{right:20px;right:2rem}html[dir=rtl] ._7w0nl{left:20px;left:2rem}", ""]);
// Exports
exports.locals = {
	"searchPatients": "_3HsSK",
	"searchPatients__formGroup--topBar": "mq4_q",
	"searchPatients__formGroup__input": "wtejs",
	"searchPatients__icon": "_7w0nl"
};
module.exports = exports;
