import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import messages from '../../messages';


const ReauthorizeContourCloudButton: FC = () => {
  const isInProgress = useSelector(selectors.isReauthContourCloudInProgress);
  const onReauthContourCloud = useAction(actions.reauthContourCloud);
  return (
    <Button
      styleModifier="primary"
      className="btn--filled mt-4"
      labelMessage={messages.buttons.reauthContourCloud}
      onClick={onReauthContourCloud}
      isInProgress={isInProgress}
    />
  );
};

export default ReauthorizeContourCloudButton;
