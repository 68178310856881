import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';


class Tabs extends React.PureComponent {

  static propTypes = {
    // Explicit props
    activeItem: PropTypes.string.isRequired,
    items     : PropTypes.arrayOf(PropTypes.string).isRequired,
    messages  : PropTypes.object.isRequired,
    errors    : PropTypes.object,
    modified  : PropTypes.object,
    // Explicit actions
    action    : PropTypes.func.isRequired,
  };


  onClick(evt, item) {
    evt.preventDefault();
    this.props.action(item);
  }


  renderTab(item, style) {
    if (!item) {
      return null;
    }
    const { activeItem, errors, modified } = this.props;
    return (
      <li
        key={item}
        className={
          cn('tab', {
            'tab--active': item === activeItem,
            'tab--error' : errors && errors[item],
          })
        }
        style={style}
      >
        <a href="" onClick={(evt) => this.onClick(evt, item)}>
          <FormattedMessage {...this.props.messages[item]} />{ modified && modified[item] && '*' }
        </a>
      </li>
    );
  }


  render() {
    const { items } = this.props;
    const maxWidth = `${100 / items.length}%`;
    const style = { flex: `0 0 ${maxWidth}`, maxWidth };
    return (
      <div className="tabs">
        { map(items, (item) => this.renderTab(item, style)) }
      </div>
    );
  }

}


export default Tabs;
