import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import { useAction } from 'hooks';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Loupe from 'svg/loupe.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import * as actions from '../../actions';
import { SEARCH_PATIENTS_FORM } from '../../constants';
import messages from '../../messages';
import styles from './SearchPatients.pcss';


interface Props {
  forId: string,
  formName?: string,
  clearOnBlur?: boolean,
}


const SearchPatients: FC<Props> = ({ forId, formName, clearOnBlur }) => {
  const id = `${forId}-search`;
  const searchFormName = formName || SEARCH_PATIENTS_FORM;
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const formValues = useSelector(App.selectors.formSelector(searchFormName));
  const setSearch = useAction(actions.setPatientsSearch, forId);
  const setFormValue = useAction(App.actions.setFormValue, searchFormName);
  const clearForm = useAction(App.actions.clearForm, searchFormName);
  useStyles(styles);

  const onSetSearch = throttle(setSearch, 300);

  const clearSearch = () => {
    setSearch('');
  };

  const onChange = (input: Input) => {
    setFormValue(input);
    onSetSearch(input.value.toLowerCase());
  };

  const onFocus = (input: Input) => {
    onSetSearch(input.value.toLowerCase());
  };

  const onBlur = () => {
    if (clearOnBlur) {
      clearForm();
    }
    setTimeout(clearSearch, 100);
  };

  useEffect(() => {
    clearSearch();
    clearForm();
  }, [activeClinicMembership]);

  return (
    <Form className={styles.searchPatients}>
      <FormGroup
        id={id}
        className={
          cn(styles.searchPatients__formGroup, {
            [styles[`searchPatients__formGroup--${forId}`]]: styles[`searchPatients__formGroup--${forId}`],
          })
        }
        formValues={formValues}
      >
        <Input
          placeholder={messages.placeholders.searchPatients}
          className={styles.searchPatients__formGroup__input}
          attributes={{ autoComplete: 'off' }}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </FormGroup>
      <Loupe className={styles.searchPatients__icon} />
    </Form>
  );
};

export default SearchPatients;
