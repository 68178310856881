export default {

  headers: {
    addNewPatient           : { id: 'hcp.headers.addNewPatient' },
    choosePatient           : { id: 'hcp.headers.choosePatient' },
    enrollingPatientFound   : { id: 'hcp.headers.enrollingPatientFound' },
    enrollingPatientNotFound: { id: 'hcp.headers.enrollingPatientNotFound' },
    declineEnrolling        : { id: 'hcp.headers.declineEnrolling' },
    inviteAppUser           : { id: 'hcp.headers.inviteAppUser' },
    invitePatient           : { id: 'hcp.headers.invitePatient' },
    mergePatients           : { id: 'hcp.headers.mergePatients' },
    mergingPatient          : { id: 'hcp.headers.mergingPatient' },
    removePatient           : { id: 'hcp.headers.removePatient' },
    listOfPatients          : { id: 'hcp.headers.listOfPatients' },
    optionalInformation     : { id: 'hcp.headers.optionalInformation' },
    patientDataComparison   : { id: 'hcp.headers.patientDataComparison' },
    clinicalProfile         : { id: 'hcp.headers.clinicalProfile' },
    personalProfile         : { id: 'hcp.headers.personalProfile' },
    personalInformation     : { id: 'hcp.headers.personalInformation' },
    healthInformation       : { id: 'hcp.headers.healthInformation' },
    resendInvitation        : { id: 'hcp.headers.resendInvitation' },
    whatToDoWithRequest     : { id: 'hcp.headers.whatToDoWithRequest' },
  },

  nav: {
    manual: { id: 'hcp.nav.manual' },
    qrCode: { id: 'hcp.nav.qrCode' },
  },

  patientsListSelector: {
    all    : { id: 'hcp.patientsListSelector.all' },
    my     : { id: 'hcp.patientsListSelector.my' },
    pending: { id: 'hcp.patientsListSelector.pending' },
  },

  buttons: {
    accept                 : { id: 'hcp.buttons.accept' },
    addPatient             : { id: 'hcp.buttons.addPatient' },
    addNewPatient          : { id: 'hcp.buttons.addNewPatient' },
    invitePatient          : { id: 'hcp.buttons.invitePatient' },
    keepPatient            : { id: 'hcp.buttons.keepPatient' },
    mergePatient           : { id: 'hcp.buttons.mergePatient' },
    removePatient          : { id: 'hcp.buttons.removePatient' },
    endVisit               : { id: 'hcp.buttons.endVisit' },
    startVisit             : { id: 'hcp.buttons.startVisit' },
    addVisitNotes          : { id: 'hcp.buttons.addVisitNotes' },
    goToProfile            : { id: 'hcp.buttons.goToProfile' },
    acceptChanges          : { id: 'hcp.buttons.acceptChanges' },
    rejectChanges          : { id: 'hcp.buttons.rejectChanges' },
    cancel                 : { id: 'hcp.buttons.cancel' },
    close                  : { id: 'hcp.buttons.close' },
    decline                : { id: 'hcp.buttons.decline' },
    previousVisitsNotes    : { id: 'hcp.buttons.previousVisitsNotes' },
    resendInvitationConfirm: { id: 'hcp.buttons.resendInvitationConfirm' },
    resendInvitationReject : { id: 'hcp.buttons.resendInvitationReject' },
    submit                 : { id: 'hcp.buttons.submit' },
    showMore               : { id: 'hcp.buttons.showMore' },
    visitsNotes            : { id: 'hcp.buttons.visitsNotes' },
  },

  labels: {
    activeProfile          : { id: 'hcp.labels.activeProfile' },
    country                : { id: 'hcp.labels.country' },
    createNewProfile       : { id: 'hcp.labels.createNewProfile' },
    diabetesType           : { id: 'hcp.labels.diabetesType' },
    email                  : { id: 'hcp.labels.email' },
    existingProfile        : { id: 'hcp.labels.existingProfile' },
    firstName              : { id: 'hcp.labels.firstName' },
    gender                 : { id: 'hcp.labels.gender' },
    female                 : { id: 'hcp.labels.female' },
    male                   : { id: 'hcp.labels.male' },
    mergeWithFoundProfile  : { id: 'hcp.labels.mergeWithFoundProfile' },
    mergeWithOtherProfile  : { id: 'hcp.labels.mergeWithOtherProfile' },
    height                 : { id: 'hcp.labels.height' },
    lastName               : { id: 'hcp.labels.lastName' },
    risk                   : { id: 'hcp.labels.risk' },
    searchPatients         : { id: 'hcp.labels.searchPatients' },
    sendEmailInvitation    : { id: 'hcp.labels.sendEmailInvitation' },
    treatmentType          : { id: 'hcp.labels.treatmentType' },
    weight                 : { id: 'hcp.labels.weight' },
    dateOfBirth            : { id: 'hcp.labels.dateOfBirth' },
    noFindedPatients       : { id: 'hcp.labels.noFindedPatients' },
    personalIdentifierType : { id: 'hcp.labels.personalIdentifierType' },
    personalIdentifierValue: { id: 'hcp.labels.personalIdentifierValue' },
    phone                  : { id: 'hcp.labels.phone' },
    additionalData         : { id: 'hcp.labels.additionalData' },
    otherInformation       : { id: 'hcp.labels.otherInformation' },
  },

  placeholders: {
    country         : { id: 'hcp.placeholders.country' },
    diabetesType    : { id: 'hcp.placeholders.diabetesType' },
    email           : { id: 'hcp.placeholders.email' },
    firstName       : { id: 'hcp.placeholders.firstName' },
    height          : { id: 'hcp.placeholders.height' },
    lastName        : { id: 'hcp.placeholders.lastName' },
    searchPatients  : { id: 'hcp.placeholders.searchPatients' },
    treatmentType   : { id: 'hcp.placeholders.treatmentType' },
    weight          : { id: 'hcp.placeholders.weight' },
    searchClinics   : { id: 'hcp.placeholders.searchClinics' },
    phone           : { id: 'hcp.placeholders.phone' },
    additionalData  : { id: 'hcp.placeholders.additionalData' },
    otherInformation: { id: 'hcp.placeholders.otherInformation' },
  },

  infos: {
    areYouSureToDeclinePatientRequest: { id: 'hcp.infos.areYouSureToDeclinePatientRequest' },
    areYouSureToRemovePatient        : { id: 'hcp.infos.areYouSureToRemovePatient' },
    choosePatientAndBind             : { id: 'hcp.infos.choosePatientAndBind' },
    patientDataMatches               : { id: 'hcp.infos.patientDataMatches' },
    mergePatients                    : { id: 'hcp.infos.mergePatients' },
    resendInvitation                 : { id: 'hcp.infos.resendInvitation' },
    qrCode                           : { id: 'hcp.infos.qrCode' },
    addPatientToFavorite             : { id: 'hcp.infos.addPatientToFavorite' },
    removePatientFromFavorite        : { id: 'hcp.infos.removePatientFromFavorite' },
    errorAddPatientToFavorite        : { id: 'hcp.infos.errorAddPatientToFavorite' },
    errorRemovePatientFromFavorite   : { id: 'hcp.infos.errorRemovePatientFromFavorite' },
  },

  alerts: {
    enrolledPatient    : { id: 'hcp.alerts.enrolledPatient' },
    mergePatientSuccess: { id: 'hcp.alerts.mergePatientSuccess' },
  },

  errors: {
    businessErrors: {
      CannotBindPatientAlreadyHasSharingRequest: { id: 'hcp.errors.businessErrors.CannotBindPatientAlreadyHasSharingRequest' },
      FailedFetchSharingRequest                : { id: 'hcp.errors.businessErrors.FailedFetchSharingRequest' },
      FailedUpdatePatient                      : { id: 'hcp.errors.businessErrors.FailedUpdatePatient' },
      FailedCreateSharingRequest               : { id: 'hcp.errors.businessErrors.FailedCreateSharingRequest' },
      FailedRejectSharingRequest               : { id: 'hcp.errors.businessErrors.FailedRejectSharingRequest' },
      InvitationCodeOrPatientLinkAlreadyExist  : { id: 'hcp.errors.businessErrors.InvitationCodeOrPatientLinkAlreadyExist' },
      PatientIdAlreadyExists                   : { id: 'hcp.errors.businessErrors.PatientIdAlreadyExists' },
      ReassignError                            : { id: 'hcp.errors.businessErrors.ReassignError' },
    },
  },
};
