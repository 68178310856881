import concat from 'lodash/concat';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import forIn from 'lodash/forIn';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import includes from 'lodash/includes';
import last from 'lodash/last';
import map from 'lodash/map';
import slice from 'lodash/slice';
import sortedUniq from 'lodash/sortedUniq';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import reject from 'lodash/reject';
import unset from 'lodash/unset';
import moment from 'moment';
import { diff, clone } from 'jsondiffpatch';
import { v4 as uuid } from 'uuid';
import { buffers } from 'redux-saga';
import {
  actionChannel, all, call, put, select,
  take, takeEvery, takeLatest,
  race, delay, getContext,
} from 'redux-saga/effects';
import takeRight from 'lodash/takeRight';
import SessionStorage from 'libs/SessionStorage';
import { decrypt, encrypt, getKeyFromPem, hybridDecrypt, hybridEncrypt } from 'helpers/crypto';
import { getStandards } from 'helpers/settings';
import { ApiError, LocalError } from 'helpers/errorTypes';
import { compactObject } from 'helpers/transformers';
import { showToast, TOAST_TYPES } from 'helpers/toast';
import { getSlug } from 'helpers/urlTools';
import { fetchDocumentId, openTransaction, commitTransaction, closeTransaction } from 'services/MdtcService';
import convertRefreshToken from 'services/migrationServices/convertRefreshToken';
import CentralStorageService from 'services/CentralStorageService';
import GoogleDriveService from 'services/GoogleDriveService';
import CloudDriveService from 'services/CloudDriveService';
import CountryLocalizationService from 'services/CountryLocalizationService';
import NotificationsService from 'services/NotificationsService';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import Visit from 'modules/Visit';
import Statistics from 'modules/Statistics';
import DataSources from 'modules/DataSources';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import messages from './messages';


function* convertSharingRequestRefreshToken(sharingRequest, prvKeyObj, pubKeyObj) {
  const { sharingRequestId, encryptedPatientRefreshToken, storageProvider, patient } = sharingRequest;
  const patientPubKeyObj = yield call(getKeyFromPem, patient.publicKey);
  const refreshToken = yield call(decrypt, encryptedPatientRefreshToken, prvKeyObj);
  const values = {
    refreshToken,
    storageProvider,
    scope    : 'SharingRequest',
    controlId: sharingRequestId,
  };
  const { exchangeToken } = yield call(convertRefreshToken, values);
  const encryptedPatientExchangeToken = yield call(hybridEncrypt, exchangeToken, pubKeyObj);
  const encryptedPatientExchangeTokenSelf = yield call(hybridEncrypt, exchangeToken, patientPubKeyObj);
  yield call(ApiService.regionalRequest, `/api/SharingRequest/clinic/setExchangeToken/${sharingRequestId}`, {
    method: 'POST',
    body  : {
      encryptedPatientExchangeToken,
      encryptedPatientExchangeTokenSelf,
    },
  });
  return encryptedPatientExchangeToken;
}

//----------------------------------------------------------------------------------------------------------------------

function* getExternalDataSourceAccessToken(payload) {
  const { encryptedDataSourceExchangeToken, prvKeyObj, externalDataSourceId, sharingRequestId } = payload;
  yield put(DataSources.actions.getAccessToken(encryptedDataSourceExchangeToken, prvKeyObj));
  const getAccessTokenResult = yield take([
    DataSources.actionTypes.GET_ACCESS_TOKEN_SUCCESS,
    DataSources.actionTypes.GET_ACCESS_TOKEN_ERROR,
  ]);
  if (getAccessTokenResult.type === DataSources.actionTypes.GET_ACCESS_TOKEN_ERROR) {
    const { error = {} } = getAccessTokenResult;
    const businessError = error.getBusinessError && error.getBusinessError();

    if (businessError.code === 'InvalidRefreshToken') {
      yield call(NotificationsService.sendSharingRequestExternalDataSourceAccessRevokedNotification,
        { externalDataSourceId, sharingRequestId });
    }
    return null;
  }
  return getAccessTokenResult;
}


function* runFetchSharingRequest(invitationCode) {
  const requestUrl = `/api/SharingRequest/clinic/${invitationCode}`;
  try {
    return yield call(ApiService.regionalRequest, requestUrl);
  } catch (err) {
    if (err.status !== 404) {
      yield call(App.dispatchError, new LocalError({ code: 'FailedFetchSharingRequest' }), messages);
    }
    return null;
  }
}


function* fetchSharingRequest(patient, clinicMembership) {
  const { encryptedInvitationCode } = patient;

  if (!encryptedInvitationCode) {
    return null;
  }

  const { encryptedPrivateKey, passphrase } = clinicMembership;
  const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
  const invitationCode = decrypt(encryptedInvitationCode, prvKeyObj);
  const sharingRequest = yield call(runFetchSharingRequest, invitationCode);

  if (!sharingRequest) {
    return null;
  }

  // MIGRATION - CONVERT REFRESH TOKEN - START
  if (!sharingRequest.encryptedPatientExchangeToken && sharingRequest.encryptedPatientRefreshToken) {
    const publicKey = get(clinicMembership, 'clinic.publicKey');
    const pubKeyObj = getKeyFromPem(publicKey);
    sharingRequest.encryptedPatientExchangeToken = yield call(
      convertSharingRequestRefreshToken, sharingRequest, prvKeyObj, pubKeyObj,
    );
  }
  // MIGRATION - CONVERT REFRESH TOKEN - END

  if (sharingRequest.sharingStatus === 'Approved') {
    const { encryptedPatientPhiSetReferenceKey, encryptedPatientExchangeToken } = sharingRequest;
    sharingRequest.patientExchangeToken = hybridDecrypt(encryptedPatientExchangeToken, prvKeyObj);
    sharingRequest.patientPhiSetReferenceKey = decrypt(encryptedPatientPhiSetReferenceKey, prvKeyObj);

    const {
      externalDataSources = [],
    } = sharingRequest;

    const payloadToGetRefreshTokens = externalDataSources.map((item) => ({
      encryptedDataSourceExchangeToken: item.encryptedDataSourceExchangeToken,
      prvKeyObj,
      externalDataSourceId            : item.externalDataSourceId,
      sharingRequestId                : sharingRequest.sharingRequestId,
    }));

    const callsPayloadToGetRefreshTokens = yield payloadToGetRefreshTokens.map(
      (payloadToGetRefreshToken) => call(getExternalDataSourceAccessToken, payloadToGetRefreshToken),
    );

    const getTokensResults = yield all(callsPayloadToGetRefreshTokens);

    sharingRequest.externalDataSources = externalDataSources.map((dataSource, index) => {
      const accessToken = get(getTokensResults[index], 'payload.response.accessToken');
      return {
        ...dataSource,
        accessToken,
      };
    });
  }
  return sharingRequest;
}

//----------------------------------------------------------------------------------------------------------------------

function* redirectToResults(clinicPatient, clinicMembership) {
  const getUrl = yield getContext('getUrl');
  const redirect = yield getContext('redirect');
  const { organizationUID, name } = get(clinicMembership, 'clinic', {});
  const clinicSlug = getSlug(name);
  const patientSlug = getSlug(`${clinicPatient.firstName} ${clinicPatient.lastName}`);
  const patientId = clinicPatient.id;
  const url = getUrl('hcp-results', { clinicSlug, organizationUID, patientSlug, patientId });
  redirect(url);
}


function* fetchPatients({ payload }) {
  try {
    const { clinicMembership } = payload;
    const profilesReferenceKey = get(clinicMembership, 'clinic.profilesReferenceKey');
    const storageProvider = get(clinicMembership, 'clinic.storageProvider');
    const accessToken = get(clinicMembership, 'accessToken');
    const documentId = yield call(fetchDocumentId, profilesReferenceKey);

    if (!documentId) {
      yield put(actions.fetchPatientsSuccess([], null));
      return;
    }

    const patients = yield call(CloudDriveService.fetchPatients, documentId, storageProvider, accessToken);
    const patientWithVisit = find(patients, { visitWith: clinicMembership.clinicHcpMembershipId });
    yield put(actions.fetchPatientsSuccess(patients, documentId));
    if (patientWithVisit) {
      yield call(redirectToResults, patientWithVisit, clinicMembership);
    }
  } catch (err) {
    yield put(actions.fetchPatientsError(err));
    yield call(App.dispatchError, err);
  }
}


function* fetchEnrollingSharingRequests({ payload }) {
  try {
    const { clinicMembership } = payload;
    const { clinicId } = clinicMembership;
    const requestUrl = `/api/SharingRequest/clinic/${clinicId}/enrolling`;
    const enrollingSharingRequests = yield call(ApiService.regionalRequest, requestUrl);
    const extendedEnrollingSharingRequests = map(enrollingSharingRequests, (esr) => {
      esr.patient.id = `esr-${esr.sharingRequestId}`;
      return esr;
    });
    yield put(actions.fetchEnrollingSharingRequestsSuccess(extendedEnrollingSharingRequests));
  } catch (err) {
    yield put(actions.fetchEnrollingSharingRequestsError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* getCurrentPatients(revisionDocumentId, storageProvider, accessToken) {
  if (!revisionDocumentId) {
    return [];
  }
  const currentProfilesDocumentId = yield select(selectors.profilesDocumentId);
  if (currentProfilesDocumentId !== revisionDocumentId) {
    return yield call(CloudDriveService.fetchPatients, revisionDocumentId, storageProvider, accessToken);
  }
  const patients = yield select(selectors.patients);
  // @TODO: It can be removed if all patient data are clean, but it need to return a clone of the array
  return patients && patients.map((p) => (
    p.phiSetReferenceKey
      ? omit(p, ['invitationCode', 'phiSetReferenceKey'])
      : { ...p }
  ));
}

//----------------------------------------------------------------------------------------------------------------------

function* linkPatientToStatisticalDb(patient, phiSet, clinicMembership, sharingRequest) {
  const { accessToken, clinic } = clinicMembership;
  const { storageProvider, profilesReferenceKey } = clinic;

  const transaction = yield call(openTransaction, [{
    lockType    : 'Exclusive',
    referenceKey: profilesReferenceKey,
  }]);
  const { transactionId, revisions } = transaction;
  const revisionDocumentId = get(revisions, [0, 'documentId']);

  try {
    yield put(Statistics.actions.linkPatientToStatisticalDb(patient, phiSet, clinicMembership, sharingRequest));
    const linkPatientProfileToStatisticalDbResult = yield take([
      Statistics.actionTypes.LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_SUCCESS,
      Statistics.actionTypes.LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_ERROR,
    ]);

    // eslint-disable-next-line max-len
    if (linkPatientProfileToStatisticalDbResult.type === Statistics.actionTypes.LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_ERROR) {
      yield call(App.dispatchError, linkPatientProfileToStatisticalDbResult.error);
      return;
    }

    patient.isFullyLinkedInStatisticalDb = true;

    const patients = yield call(getCurrentPatients, revisionDocumentId, storageProvider, accessToken);
    const idx = findIndex(patients, { id: patient.id });
    const updatedPatients = clone(patients);
    updatedPatients[idx] = patient;

    const auditDocument = diff(patients, updatedPatients);

    let result;

    switch (storageProvider) {
      case 'CentralStorage': {
        // eslint-disable-next-line max-len
        result = yield call(CentralStorageService.enableFullLinkingPatient, accessToken, revisionDocumentId, patient.id);
        break;
      }
      case 'GoogleDrive': {
        result = yield call(GoogleDriveService.uploadJson, updatedPatients, accessToken, auditDocument);
        break;
      }
      default: {
        const err = new LocalError({ code: 'UnknownStorageProvider' });
        yield call(App.dispatchError, err, CloudDrive.messages);
        throw err;
      }
    }

    const { documentId, auditDocumentId } = result;

    const updatedRevisions = [{
      previousDocumentId: revisionDocumentId,
      newDocumentId     : documentId,
      referenceKey      : profilesReferenceKey,
      auditDocumentId,
    }];

    const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);

    yield put(actions.updatePatientSuccess(updatedPatients, documentId, patient));
    yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
  } catch (err) {
    yield call(closeTransaction, transactionId);
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* addPatient({ payload }) {
  try {
    const { patientValues, clinicMembership } = payload;
    const hcpProfileId = get(clinicMembership, 'hcpProfileId');
    const { clinicHcpMembershipId, accessToken, clinic } = clinicMembership;
    const {
      clinicId, countryId, name: clinicName,
      publicKey, storageProvider, profilesReferenceKey,
      settings: clinicSettings,
    } = clinic;
    const pubKeyObj = getKeyFromPem(publicKey);

    const measurements = compactObject(pick(patientValues, ['weight', 'height']));
    const phiSet = compactObject(pick(patientValues, ['diabetesType', 'treatmentType', 'glucoseLevelTargets']));
    const patientHealthData = compactObject(pick(
      patientValues,
      ['diabetesType', 'treatmentType', 'weight', 'height'],
    ));

    const sourceType = get(
      patientValues, 'source.type', App.constants.PATIENT_DATABASES.GlucoControOnline.type,
    );

    // const profilesDocumentId = yield call(fetchDocumentId, profilesReferenceKey);
    let { invitationCode } = { ...patientValues };
    let isSendSharingRequest = false;

    const patient = { ...patientValues, countryId };

    unset(patient, 'diabetesType');
    unset(patient, 'treatmentType');
    unset(patient, 'weight');
    unset(patient, 'height');

    patient.id = patientValues.id || uuid();
    patient.createTimestamp = patientValues.createTimestamp || +moment().locale('en--account').format('X');
    const phiSetReferenceKey = uuid();
    patient.encryptedPhiSetReferenceKey = encrypt(phiSetReferenceKey, pubKeyObj);
    patient.hcpProfileId = hcpProfileId;

    if (patient.email && !invitationCode) {
      invitationCode = uuid();
      isSendSharingRequest = true;
    }

    if (invitationCode) {
      patient.encryptedInvitationCode = encrypt(invitationCode, pubKeyObj);
      unset(patient, 'invitationCode');
    }

    const transaction = yield call(openTransaction, [{
      lockType    : 'Exclusive',
      referenceKey: profilesReferenceKey,
    }]);
    const { transactionId, revisions } = transaction;
    const revisionDocumentId = get(revisions, [0, 'documentId']);

    // Transaction try
    try {
      const patients = yield call(getCurrentPatients, revisionDocumentId, storageProvider, accessToken);

      const duplicateEmailPatient = find(patients, { email: patientValues.email });
      if (duplicateEmailPatient) {
        throw new ApiError({
          status       : 409,
          businessError: { code: 'ProfileAlreadyExist' },
        });
      }

      const findDuplicatePatient = (p) => {
        if (patientValues.sourcePatientId) {
          return p.id === patientValues.id || p.sourcePatientId === patientValues.sourcePatientId;
        }
        return p.id === patientValues.id;
      };

      const duplicateIdPatientIdx = findIndex(patients, findDuplicatePatient);
      if (duplicateIdPatientIdx >= 0) {
        const duplicateIdPatient = patients[duplicateIdPatientIdx];
        if (duplicateIdPatient.encryptedPhiSetReferenceKey) {
          throw new ApiError({
            status       : 409,
            businessError: { code: 'PatientIdAlreadyExists' },
          });
        } else {
          patients.splice(duplicateIdPatientIdx, 1);
        }
      }

      const updatedPatients = [...patients, patient];

      const auditDocument = diff(patients, updatedPatients);

      let result;

      switch (storageProvider) {
        case 'CentralStorage': {
          result = yield call(CentralStorageService.addPatient, accessToken, revisionDocumentId, patient);
          break;
        }
        case 'GoogleDrive': {
          result = yield call(GoogleDriveService.uploadJson, updatedPatients, accessToken, auditDocument);
          break;
        }
        default: {
          const err = new LocalError({ code: 'UnknownStorageProvider' });
          yield put(actions.addPatientError(err));
          yield call(App.dispatchError, err, CloudDrive.messages);
          return;
        }
      }

      const { documentId, auditDocumentId } = result;

      const updatedRevisions = [{
        previousDocumentId: revisionDocumentId,
        newDocumentId     : documentId,
        referenceKey      : profilesReferenceKey,
        auditDocumentId,
      }];

      const countrySettings = yield select(App.selectors.countrySettings);
      const standards = getStandards(null, countrySettings, clinicSettings);
      // if (!isEmpty(phiSet) || !isEmpty(measurements)) {
      phiSet.encryptedStatisticalPersonalityId = encrypt(uuid(), pubKeyObj);
      const statsPhiSet = {
        ...phiSet,
        summaryData: {
          lastWeight: measurements.weight,
          lastHeight: measurements.height,
        },
      };
      yield all([
        put(CloudDrive.actions.storeMeasurements(
          measurements,
          phiSet,
          null,
          { phiSetReferenceKey, storageProvider, accessToken },
        )),
        put(Statistics.actions.sendStatisticsForClinic(
          { ...patient, sourceType },
          statsPhiSet,
          {},
          standards,
          clinicMembership,
        )),
      ]);
      // }

      const [storeMeasurementsResult, sendStatisticsForClinicResult] = yield all([
        take([
          CloudDrive.actionTypes.STORE_MEASUREMENTS_SUCCESS,
          CloudDrive.actionTypes.STORE_MEASUREMENTS_ERROR,
        ]),
        take([
          Statistics.actionTypes.SEND_STATISTICS_FOR_CLINIC_SUCCESS,
          Statistics.actionTypes.SEND_STATISTICS_FOR_CLINIC_ERROR,
        ]),
      ]);

      const err = storeMeasurementsResult.error || sendStatisticsForClinicResult.error;
      if (err) {
        yield put(actions.addPatientError(err));
        return;
      }

      yield put(Statistics.actions.linkPatientToStatisticalDb(
        patient, phiSet, clinicMembership,
      ));

      const linkPatientResult = yield take([
        Statistics.actionTypes.LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_SUCCESS,
        Statistics.actionTypes.LINK_PATIENT_PROFILE_TO_STATISTICAL_DB_ERROR,
      ]);

      if (linkPatientResult.error) {
        yield put(actions.addPatientError(linkPatientResult.error));
        return;
      }

      const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);

      const encryptedClinicPatientProfileId = encrypt(patient.id, pubKeyObj);
      yield call(NotificationsService.sendClinicPatientProfileCreatedNotification, {
        clinicId,
        clinicName,
        patientFirstName: patient.firstName,
        patientLastName : patient.lastName,
        encryptedClinicPatientProfileId,
      });

      if (isSendSharingRequest) {
        yield put(actions.createSharingRequest(
          clinicHcpMembershipId,
          invitationCode,
          patient.email,
          encryptedClinicPatientProfileId,
          patient,
          patientHealthData,
        ));
      }

      yield put(actions.addPatientSuccess(updatedPatients, documentId, patient));
    } catch (err) {
      yield call(closeTransaction, transactionId);
      yield put(actions.addPatientError(err));
      yield call(App.dispatchError, err, CloudDrive.messages);
    }
  } catch (err) {
    yield put(actions.addPatientError(err));
    yield call(App.dispatchError, err, CloudDrive.messages);
  }
}


function* enrollPatient({ payload }) {
  try {
    const { sharingRequest, patientValues, clinicMembership } = payload;

    patientValues.id = uuid();
    yield put(actions.addPatient(patientValues, clinicMembership));
    const addPatientResult = yield take([
      actionTypes.ADD_PATIENT_SUCCESS,
      actionTypes.ADD_PATIENT_ERROR,
    ]);
    if (addPatientResult.type === actionTypes.ADD_PATIENT_ERROR) {
      yield put(actions.enrollPatientError(addPatientResult.error));
      return;
    }
    let { clinicPatient } = addPatientResult.payload;
    yield put(actions.enrollPatientTransition(sharingRequest));

    yield put(actions.bindSharingRequestWithClinicPatient(sharingRequest, clinicPatient, clinicMembership));
    const bindSharingRequestWithClinicPatientResult = yield take([
      actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_SUCCESS,
      actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_ERROR,
    ]);
    if (bindSharingRequestWithClinicPatientResult.type === actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_ERROR) {
      yield call(redirectToResults, clinicPatient, clinicMembership);
      yield put(actions.enrollPatientError(bindSharingRequestWithClinicPatientResult.error, sharingRequest));
      return;
    }
    clinicPatient = bindSharingRequestWithClinicPatientResult.payload.clinicPatient;
    yield call(redirectToResults, clinicPatient, clinicMembership);
    yield put(actions.enrollPatientSuccess(clinicPatient));
  } catch (err) {
    yield put(actions.enrollPatientError(err));
    yield call(App.dispatchError, err, CloudDrive.messages);
  }
}


function* mergePatient({ payload }) {
  try {
    const { patient, destinationPatient, sharingRequest, clinicMembership } = payload;
    if (destinationPatient.encryptedInvitationCode) {
      const { encryptedPrivateKey, passphrase } = clinicMembership;
      const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
      const currentInvitationCode = decrypt(destinationPatient.encryptedInvitationCode, prvKeyObj);
      const existingSharingRequest = yield call(runFetchSharingRequest, currentInvitationCode);
      if (existingSharingRequest) {
        const err = new LocalError({ code: 'CannotBindPatientAlreadyHasSharingRequest' });
        yield put(actions.mergePatientError(err));
        yield call(App.dispatchError, err, messages);
        return;
      }
      const invitationCode = uuid();
      yield put(actions.updatePatient(destinationPatient, { invitationCode }, clinicMembership));
      const updatePatientResult = yield take([
        actionTypes.UPDATE_PATIENT_SUCCESS,
        actionTypes.UPDATE_PATIENT_ERROR,
      ]);
      if (updatePatientResult.type === actionTypes.UPDATE_PATIENT_ERROR) {
        yield put(actions.mergePatientError(updatePatientResult.error));
        return;
      }
      destinationPatient.invitationCode = invitationCode;
    }

    yield put(actions.bindSharingRequestWithClinicPatient(sharingRequest, destinationPatient, clinicMembership));
    const bindSharingRequestWithClinicPatientResult = yield take([
      actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_SUCCESS,
      actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_ERROR,
    ]);
    if (bindSharingRequestWithClinicPatientResult.type === actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT_ERROR) {
      yield put(actions.mergePatientError(bindSharingRequestWithClinicPatientResult.error));
      return;
    }

    const getUrl = yield getContext('getUrl');
    const redirect = yield getContext('redirect');
    const { organizationUID, name } = get(clinicMembership, 'clinic', {});
    const clinicSlug = getSlug(name);
    const patientSlug = getSlug(`${destinationPatient.firstName} ${destinationPatient.lastName}`);
    const patientId = destinationPatient.id;
    const url = getUrl('hcp-results', { clinicSlug, organizationUID, patientSlug, patientId });
    redirect(url);

    yield put(actions.removePatient(patient, clinicMembership));
    const removePatientResult = yield take([
      actionTypes.REMOVE_PATIENT_SUCCESS,
      actionTypes.REMOVE_PATIENT_ERROR,
    ]);
    if (removePatientResult.type === actionTypes.REMOVE_PATIENT_ERROR) {
      yield put(actions.mergePatientError(removePatientResult.error));
      return;
    }
    yield put(actions.mergePatientSuccess());
    yield put(App.actions.setAlert({
      type    : 'success',
      message : messages.alerts.mergePatientSuccess,
      isGlobal: true,
    }));
  } catch (err) {
    yield put(actions.mergePatientError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* updatePatient({ payload }) {
  try {
    const { patient, newPatientValues, clinicMembership } = payload;
    const valuesToUpdate = {};
    forIn(newPatientValues, (value, key) => {
      if (value !== patient[key]) {
        valuesToUpdate[key] = value;
      }
    });

    if (isEmpty(valuesToUpdate)) {
      yield put(actions.updatePatientSuccess());
      return;
    }

    const pubKeyPem = get(clinicMembership, 'clinic.publicKey');
    const pubKeyObj = getKeyFromPem(pubKeyPem);

    if (newPatientValues.invitationCode) {
      newPatientValues.encryptedInvitationCode = encrypt(newPatientValues.invitationCode, pubKeyObj);
    }

    const updatedPatient = omit(
      { ...patient, ...newPatientValues },
      ['invitationCode', 'phiSetReferenceKey', 'storageProvider', 'accessToken'],
    );
    const { accessToken } = clinicMembership;
    const profilesReferenceKey = get(clinicMembership, 'clinic.profilesReferenceKey');
    const storageProvider = get(clinicMembership, 'clinic.storageProvider');

    const transaction = yield call(openTransaction, [{
      lockType    : 'Exclusive',
      referenceKey: profilesReferenceKey,
    }]);
    const { transactionId, revisions } = transaction;
    const revisionDocumentId = get(revisions, [0, 'documentId']);

    const patients = yield call(getCurrentPatients, revisionDocumentId, storageProvider, accessToken);
    const idx = findIndex(patients, { id: updatedPatient.id });
    const updatedPatients = clone(patients);
    updatedPatients[idx] = updatedPatient;

    let result;

    switch (storageProvider) {
      case 'CentralStorage': {
        result = yield call(CentralStorageService.updatePatient, accessToken, revisionDocumentId, updatedPatient);
        break;
      }
      case 'GoogleDrive': {
        const auditDocument = diff(patients, updatedPatients);
        result = yield call(GoogleDriveService.uploadJson, updatedPatients, accessToken, auditDocument);
        break;
      }
      default: {
        const err = new LocalError({ code: 'UnknownStorageProvider' });
        yield put(actions.addPatientError(err));
        yield call(App.dispatchError, err, CloudDrive.messages);
        return;
      }
    }

    const updatedRevisions = [{
      previousDocumentId: revisionDocumentId,
      newDocumentId     : result.documentId,
      referenceKey      : profilesReferenceKey,
      auditDocumentId   : result.auditDocumentId,
    }];
    const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
    yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);
    yield put(actions.updatePatientSuccess(updatedPatients, result.documentId, updatedPatient));
  } catch (err) {
    yield put(actions.updatePatientError(err));
    yield call(App.dispatchError, err);
  }
}


function* previewPatientOpenTab({ payload }) {
  const { deviceData, clinicSlug, organizationUID } = payload;
  try {
    SessionStorage.setItem('previewPatientDownload', JSON.stringify(deviceData));

    if (process.env.BROWSER) {
      const domain = yield getContext('domain');
      const getUrl = yield getContext('getUrl');
      const previewResults = getUrl('hcp-preview-results', { clinicSlug, organizationUID });
      const url = `${domain}${previewResults}`;
      window.open(url, '_blank');
    }
    yield put(actions.previewPatientOpenTabSuccess());
  } catch (err) {
    yield put(actions.previewPatientOpenTabError(err));
    yield call(App.dispatchError, err, messages);
  }
}


//----------------------------------------------------------------------------------------------------------------------

function* startPatientVisit({ payload }) {
  try {
    const {
      clinicPatient,
      phiSet,
      phiSetDocumentId,
      clinicMembership,
    } = payload;

    const newPatientValues = { visitWith: clinicMembership.clinicHcpMembershipId };

    const calls = [
      put(Visit.actions.addVisit(phiSet, phiSetDocumentId, clinicPatient, clinicMembership, actions.setPhiSet)),
      put(actions.updatePatient(clinicPatient, newPatientValues, clinicMembership)),
    ];

    const takes = [
      take([Visit.actionTypes.ADD_VISIT_SUCCESS, Visit.actionTypes.ADD_VISIT_ERROR]),
      take([actionTypes.UPDATE_PATIENT_SUCCESS, actionTypes.UPDATE_PATIENT_ERROR]),
    ];

    yield all(calls);
    const [addVisitResult, updatePatientResult] = yield all(takes);

    if (addVisitResult.type === Visit.actionTypes.ADD_VISIT_ERROR) {
      yield put(actions.startPatientVisitError(addVisitResult.error));
      return;
    }
    if (updatePatientResult.type === actionTypes.UPDATE_PATIENT_ERROR) {
      yield put(actions.startPatientVisitError(updatePatientResult.error));
      return;
    }

    yield put(CloudDrive.actions.startRemoveNotesQueue());

    yield put(actions.startPatientVisitSuccess());
  } catch (err) {
    yield put(actions.startPatientVisitError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* endPatientVisit({ payload }) {

  try {
    const {
      clinicPatient,
      phiSet,
      clinicMembership,
      visit,
      sharingRequest,
    } = payload;

    const isStoreNotesInProgress = yield select(CloudDrive.selectors.isStoreNotesInProgress);
    if (isStoreNotesInProgress) {
      const storeNotesResult = yield take([
        CloudDrive.actionTypes.REMOVE_NOTES_QUEUE_FINISHED,
        CloudDrive.actionTypes.STORE_NOTES_ERROR,
      ]);
      if (storeNotesResult.type === CloudDrive.actionTypes.STORE_NOTES_ERROR) {
        yield put(actions.endPatientVisitError(storeNotesResult.error));
        return;
      }
    }
    const isRemoveNoteInProgress = yield select(CloudDrive.selectors.isRemoveNoteInProgress);
    if (isRemoveNoteInProgress) {
      const removeNoteResult = yield take([
        CloudDrive.actionTypes.REMOVE_NOTES_QUEUE_FINISHED,
        CloudDrive.actionTypes.REMOVE_NOTE_ERROR,
      ]);
      if (removeNoteResult.type === CloudDrive.actionTypes.REMOVE_NOTE_ERROR) {
        yield put(actions.endPatientVisitError(removeNoteResult.error));
        return;
      }
    }
    yield put(CloudDrive.actions.stopRemoveNotesQueue());
    const newPatientValues = { visitWith: null };
    yield put(actions.updatePatient(clinicPatient, newPatientValues, clinicMembership));
    const updatePatientResult = yield take([
      actionTypes.UPDATE_PATIENT_SUCCESS,
      actionTypes.UPDATE_PATIENT_ERROR,
    ]);
    if (updatePatientResult.type === actionTypes.UPDATE_PATIENT_ERROR) {
      yield put(actions.endPatientVisitError(updatePatientResult.error));
      return;
    }

    const { phisetVisitId } = visit;
    const notes = yield select(selectors.notes);
    const visitNotes = filter(notes, { phisetVisitId });

    yield put(Visit.actions.endVisit(visitNotes));
    if (sharingRequest && sharingRequest.sharingStatus === 'Approved') {
      yield put(CloudDrive.actions.pushVisit(phiSet, sharingRequest, clinicMembership));
      yield take([CloudDrive.actionTypes.PUSH_VISIT_SUCCESS, CloudDrive.actionTypes.PUSH_VISIT_ERROR]);

      if (filter(visitNotes, (n) => n.noteType !== 'Comment').length) {
        const { sharingRequestId } = sharingRequest;
        const clinicName = get(clinicMembership, 'clinic.name');
        yield call(NotificationsService.sendNoteWrittenByHcpNotification, { clinicName, sharingRequestId });
      }
    }
    yield put(actions.endPatientVisitSuccess());
  } catch (err) {
    yield put(actions.endPatientVisitError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* cancelSharingRequest(sharingRequest) {
  if (!sharingRequest || !includes(constants.CANCELABLE_SHARING_REQUEST_STATUSES, sharingRequest.sharingStatus)) {
    return sharingRequest;
  }
  const requestUrl = `/api/SharingRequest/clinic/${sharingRequest.sharingRequestId}/cancel`;
  yield call(ApiService.regionalRequest, requestUrl, {
    method: 'PUT',
  });

  return {
    ...sharingRequest,
    sharingStatus: 'Cancelled',
  };
}


function* removeSharingRequest({ payload }) {
  try {
    const { sharingRequest } = payload;
    const cancelledSharingRequest = yield call(cancelSharingRequest, sharingRequest);
    yield put(actions.removeSharingRequestSuccess(cancelledSharingRequest));
  } catch (err) {
    yield put(actions.removeSharingRequestError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* deleteSharingRequest(patient, clinicMembership) {
  const { encryptedInvitationCode } = patient;
  if (!encryptedInvitationCode) {
    return;
  }
  const sharingRequest = yield call(fetchSharingRequest, patient, clinicMembership);
  if (sharingRequest) {
    yield call(cancelSharingRequest, sharingRequest);
  }
}


function* deletePatientDocuments(documentIds, driveService, accessToken) {
  const len = 6;
  const attempts = 2;
  let attempt = 1;
  let documents = [...documentIds];
  let errors = [];
  while (documents.length && attempt <= attempts) {
    const documentsToDelete = slice(documents, 0, len);
    documents = slice(documents, len);
    const results = yield all(map(
      documentsToDelete,
      (documentId) => call(driveService.deleteFile, documentId, accessToken),
    ));
    errors = concat(errors, difference(documentsToDelete, results));
    if (!documents.length && errors.length) {
      documents = [...errors];
      errors = [];
      attempt += 1;
    }
  }
}


function* deletePatientVisits(phiSet, prvKeyObj) {
  const visits = get(phiSet, 'visits');
  const visitCalls = map(visits, (visit) => {
    const { encryptedVisitMetadataId } = visit;
    const visitMetadataId = decrypt(encryptedVisitMetadataId, prvKeyObj);
    return call(ApiService.regionalRequest, `/api/Visit/${visitMetadataId}`, { method: 'DELETE' });
  });
  yield all(visitCalls);
}


function* removePatient({ payload }) {
  try {
    const { patient, clinicMembership } = payload;

    const { id, encryptedPhiSetReferenceKey, encryptedInvitationCode } = patient;
    const { accessToken, clinic, encryptedPrivateKey, passphrase } = clinicMembership;
    const { storageProvider, profilesReferenceKey } = clinic;

    const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
    const phiSetReferenceKey = decrypt(encryptedPhiSetReferenceKey, prvKeyObj);

    try {
      yield call(deleteSharingRequest, patient, clinicMembership);
    } catch (err) {
      yield put(actions.removePatientError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    // Transaction try
    const transaction = yield call(openTransaction, [
      {
        lockType    : 'Exclusive',
        referenceKey: profilesReferenceKey,
      },
      {
        lockType    : 'Exclusive',
        referenceKey: phiSetReferenceKey,
      },
    ]);
    const { transactionId, revisions } = transaction;

    // Transaction try
    try {
      const profilesRevisionDocumentId = get(find(revisions, { referenceKey: profilesReferenceKey }), 'documentId');
      const phiSetRevisionDocumentId = get(find(revisions, { referenceKey: phiSetReferenceKey }), 'documentId');
      const phiSet = yield call(CloudDriveService.fetchPhiSet, phiSetRevisionDocumentId, storageProvider, accessToken);
      const patients = yield call(getCurrentPatients, profilesRevisionDocumentId, storageProvider, accessToken);
      const updatedPatients = reject(patients, { id });
      let result;

      switch (storageProvider) {
        case 'CentralStorage': {
          result = yield call(
            CentralStorageService.removePatient, accessToken, profilesRevisionDocumentId, patient,
          );
          break;
        }
        case 'GoogleDrive': {
          result = yield call(GoogleDriveService.uploadJson, updatedPatients, accessToken);
          break;
        }
        default: {
          const err = new LocalError({ code: 'UnknownStorageProvider' });
          yield put(actions.addPatientError(err));
          yield call(App.dispatchError, err, CloudDrive.messages);
          return;
        }
      }

      // Remove statistics
      yield put(Statistics.actions.deleteStatisticsFromClinic(phiSet, clinicMembership));
      const deleteStatisticsResult = yield take([
        Statistics.actionTypes.DELETE_STATISTICS_FROM_CLINIC_SUCCESS,
        Statistics.actionTypes.DELETE_STATISTICS_FROM_CLINIC_ERROR,
      ]);
      if (deleteStatisticsResult.type === Statistics.actionTypes.DELETE_STATISTICS_FROM_CLINIC_ERROR) {
        yield call(closeTransaction, transactionId);
        yield put(actions.removePatientError(deleteStatisticsResult.error));
        yield call(App.dispatchError, deleteStatisticsResult.error, Statistics.messages);
        return;
      }

      const updatedRevisions = [
        {
          previousDocumentId: profilesRevisionDocumentId,
          newDocumentId     : result.documentId,
          referenceKey      : profilesReferenceKey,
          auditDocumentId   : result.auditDocumentId,
        },
      ];
      if (phiSetRevisionDocumentId) {
        updatedRevisions.push({
          previousDocumentId: phiSetRevisionDocumentId,
          newDocumentId     : null,
          referenceKey      : phiSetReferenceKey,
        });
      }

      const forksHistory = yield call(commitTransaction, transactionId, updatedRevisions);
      yield call(CloudDriveService.clearForks, forksHistory, storageProvider, accessToken);

      if (storageProvider === 'CentralStorage') {
        yield call(CentralStorageService.removePhiData, phiSetRevisionDocumentId, accessToken);
        yield call(deletePatientVisits, phiSet, prvKeyObj);
        yield put(actions.removePatientSuccess(updatedPatients, result.documentId));
        return;
      }

      // Remove forked files
      let documentIds = [];
      const dataIndex = get(phiSet, 'dataIndex', {});
      forIn(dataIndex, (di) => {
        if (di.readingsDocumentId) documentIds.push(di.readingsDocumentId);
        if (di.measurementsDocumentId) documentIds.push(di.measurementsDocumentId);
        if (di.notesDocumentId) documentIds.push(di.notesDocumentId);
        if (di.cgmReadingsDocumentId) documentIds.push(di.cgmReadingsDocumentId);
        if (di.relatedDataDocumentId) documentIds.push(di.relatedDataDocumentId);
      });

      const imports = get(phiSet, 'imports');
      forIn(imports, (imp) => {
        const documents = get(imp, 'documents', []);
        documentIds = concat(documentIds, map(documents, (doc) => doc.importDocumentId));
      });

      const driveService = CloudDriveService.getDriveService(storageProvider);
      yield call(deletePatientDocuments, documentIds, driveService, accessToken);
      if (!encryptedInvitationCode) yield call(deletePatientVisits, phiSet, prvKeyObj);
      yield put(actions.removePatientSuccess(updatedPatients, result.documentId));
    } catch (err) {
      yield call(closeTransaction, transactionId);
      yield put(actions.removePatientError(err));
      yield call(App.dispatchError, err, CloudDrive.messages);
    }
  } catch (err) {
    yield put(actions.removePatientError(err));
    yield call(App.dispatchError, err, CloudDrive.messages);
  }
}
//----------------------------------------------------------------------------------------------------------------------


function* reassignSharingRequest(encryptedInvitationCodes, newHcpId, clinicMembership) {
  if (encryptedInvitationCodes.every((encryptedInvitationCode) => !encryptedInvitationCode)) {
    return;
  }
  const clinicId = get(clinicMembership, 'clinic.clinicId');
  const { encryptedPrivateKey, passphrase } = clinicMembership;
  const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
  const invitationCodes = encryptedInvitationCodes.map(
    (encryptedInvitationCode) => decrypt(encryptedInvitationCode, prvKeyObj),
  );
  const requestUrl = '/api/SharingRequest/clinic/changeAssignment';
  yield call(ApiService.regionalRequest, requestUrl, {
    method: 'POST',
    body  : {
      invitationCodes,
      newHcpId,
      clinicId,
    },
  });
}


function* reassignHcp({ payload }) {
  try {
    const { patient, newClinicMembership, clinicMembership } = payload;
    yield call(
      reassignSharingRequest,
      [patient.encryptedInvitationCode],
      newClinicMembership.hcpProfileId,
      clinicMembership,
    );
    yield put(actions.updatePatient(patient, { hcpProfileId: newClinicMembership.hcpProfileId }, clinicMembership));
    yield take([
      actionTypes.UPDATE_PATIENT_SUCCESS,
      actionTypes.UPDATE_PATIENT_ERROR,
    ]);
    yield put(actions.reassignHcpSuccess());
  } catch (err) {
    yield put(actions.reassignHcpError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchCountryLocalization(patient, shouldRefresh = false) {
  const { countryId } = patient;
  const countrySettings = yield select(selectors.countrySettings);
  if (countrySettings && countrySettings.countryId !== countryId && !shouldRefresh) {
    const informationTemplate = yield select(selectors.informationTemplate);
    return { countrySettings, informationTemplate };
  }
  yield put(actions.fetchPatientCountryLocalization(countryId));
  yield put(actions.fetchPayers(countryId));

  const [fetchPatientCountryLocalizationResult, fetchPayersResult] = yield all([
    take([
      actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION_SUCCESS,
      actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION_ERROR,
    ]),
    take([
      actionTypes.FETCH_PAYERS_SUCCESS,
      actionTypes.FETCH_PAYERS_ERROR,
    ]),
  ]);

  if (fetchPatientCountryLocalizationResult.type === actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION_ERROR) {
    throw fetchPatientCountryLocalizationResult.error;
  }

  if (fetchPayersResult.type === actionTypes.FETCH_PAYERS_ERROR) {
    throw fetchPayersResult.error;
  }
  return fetchPatientCountryLocalizationResult.payload;
}


function migrateCustomPatientIdentifiersValues(patient, informationTemplate) {
  const personalIdentifierTemplate = find(informationTemplate, { name: 'personalIdentifier' });
  if (!personalIdentifierTemplate) {
    return patient;
  }
  const { customIdentifierId, value: customIdentifierValue } = patient.customPatientIdentifiersValues[0];
  const personalIdentifierTypeTemplate = find(
    get(find(personalIdentifierTemplate.fields, { name: 'personalIdentifierType' }), 'options'),
    { customIdentifierId },
  );
  if (personalIdentifierTypeTemplate) {
    patient.personalIdentifier = {
      personalIdentifierType : personalIdentifierTypeTemplate.value,
      personalIdentifierValue: customIdentifierValue,
    };
    unset(patient, 'customPatientIdentifiersValues');
  }
  return patient;
}


function* activatePatient({ payload }) {
  try {
    const { patient, clinicMembership } = payload;
    const { encryptedPrivateKey, passphrase, accessToken } = clinicMembership;
    const { encryptedPhiSetReferenceKey, encryptedInvitationCode } = patient;
    const storageProvider = get(clinicMembership, 'clinic.storageProvider');
    patient.countryId = clinicMembership.clinic.countryId;
    const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
    const phiSetReferenceKey = decrypt(encryptedPhiSetReferenceKey, prvKeyObj);
    let invitationCode;
    if (encryptedInvitationCode) {
      invitationCode = decrypt(encryptedInvitationCode, prvKeyObj);
    }

    const [sharingRequest, countryLocalization] = yield all([
      call(fetchSharingRequest, patient, clinicMembership),
      call(fetchCountryLocalization, patient),
    ]);

    if (!isEmpty(patient.customPatientIdentifiersValues)) {
      const { informationTemplate } = countryLocalization;
      yield call(migrateCustomPatientIdentifiersValues, patient, informationTemplate); // mutates patient
    }

    yield put(Visit.actions.clearVisits());
    yield put(actions.activatePatientSuccess(
      {
        ...patient, storageProvider, accessToken, phiSetReferenceKey, invitationCode,
      },
      sharingRequest,
    ));
  } catch (err) {
    yield put(actions.activatePatientError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* setPhiSet({ payload }) {
  try {
    const { phiSet, phiSetDocumentId, phiSetPatientId } = payload;
    const activePatient = yield select(selectors.activePatient);
    if (activePatient.id !== phiSetPatientId) {
      return;
    }
    const activeClinicMembership = yield select(Account.selectors.activeClinicMembership);
    const activeClinicMembershipId = activeClinicMembership.clinicHcpMembershipId;
    const visits = get(phiSet, 'visits', []);
    let activeVisit = null;
    if (activePatient.visitWith === activeClinicMembershipId) {
      activeVisit = last(visits);
      yield put(Visit.actions.resumeVisit(activeVisit, activePatient.id));
      yield put(CloudDrive.actions.startRemoveNotesQueue());
    }
    const visitsWithNotes = filter(
      activeVisit ? visits.slice(0, -1) : visits,
      (visit) => get(visit, 'associatedDataIndexKeys.notes', []).length,
    );
    const lastVisitsWithNotes = takeRight(visitsWithNotes, 3).reverse();
    if (lastVisitsWithNotes.length) {
      const { encryptedPrivateKey, passphrase } = activeClinicMembership;
      yield put(Visit.actions.fetchVisitsMetadata(lastVisitsWithNotes, encryptedPrivateKey, passphrase));
      const associatedNotesBatches = sortedUniq(flatten(
        map(lastVisitsWithNotes, (visit) => get(visit, 'associatedDataIndexKeys.notes', [])),
      ).sort());
      if (associatedNotesBatches.length) {
        const { phiSetReferenceKey, accessToken, storageProvider } = activePatient;
        const notesBatchesIndex = yield select(selectors.notesBatchesIndex);
        yield put(CloudDrive.actions.fetchNotes({
          phiSet,
          phiSetReferenceKey,
          phiSetDocumentId,
          notesBatchesIndex,
          accessToken,
          storageProvider,
          batches      : associatedNotesBatches,
          successAction: actions.setNotes,
        }));
      }
    }
  } catch (err) {
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* createSharingRequest({ payload }) {
  try {
    const {
      clinicHcpMembershipId,
      invitationCode,
      patientEmailAddress,
      encryptedClinicPatientProfileId,
      clinicPatient,
      clinicPatientHealthData,
    } = payload;
    const patientFields = [
      'firstName', 'lastName', 'dateOfBirth', 'gender', 'payer', 'personalIdentifier',
    ];
    let patientDataFromClinic = null;
    if (clinicPatient) {
      patientDataFromClinic = { ...pick(clinicPatient, patientFields), ...clinicPatientHealthData };
      const country = yield select(App.selectors.countryById(clinicPatient.countryId));
      patientDataFromClinic.countryCode = country.alpha2Code;
      delete patientDataFromClinic.customPatientIdentifiersValues;
    }

    const requestUrl = '/api/SharingRequest/clinic';
    const sharingRequest = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : {
        clinicHcpMembershipId,
        invitationCode,
        patientEmailAddress,
        encryptedLocalPatientProfileId: encryptedClinicPatientProfileId,
        patientDataFromClinic,
      },
    });
    yield put(actions.createSharingRequestSuccess(sharingRequest));
  } catch (err) {
    yield put(actions.createSharingRequestError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* resendSharingRequest({ payload }) {
  try {
    const { sharingRequest } = payload;
    const requestUrl = `/api/SharingRequest/clinic/${sharingRequest.sharingRequestId}/resend`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
    });
    yield put(actions.resendSharingRequestSuccess());
  } catch (err) {
    yield put(actions.resendSharingRequestError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* checkSharingRequestApproveWorker(invitationCode, successAction) {
  const requestUrl = `/api/SharingRequest/clinic/${invitationCode}`;
  while (true) {
    try {
      yield delay(3000);
      const sharingRequest = yield call(ApiService.regionalRequest, requestUrl);
      if (sharingRequest && sharingRequest.sharingStatus === 'Enrolling') {
        sharingRequest.patient.id = `esr-${sharingRequest.sharingRequestId}`;
        yield put(successAction(sharingRequest));
        yield put(actions.stopCheckSharingRequestApprove());
      }
    } catch (err) {
      // Background process.
    }
  }
}


function* checkSharingRequestApprove({ payload }) {
  try {
    const { invitationCode, successAction } = payload;
    yield race([
      call(checkSharingRequestApproveWorker, invitationCode, successAction),
      take(actionTypes.STOP_CHECK_SHARING_REQUEST_APPROVE),
    ]);
  } catch (err) {
    yield put(actions.checkSharingRequestApproveError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* bindSharingRequestWithPatientProfile({ payload }) {
  try {
    const { sharingRequest, clinicMembership } = payload;
    let { clinicPatient } = payload;

    const pubKey = get(clinicMembership, 'clinic.publicKey');
    const pubKeyObj = getKeyFromPem(pubKey);

    const { id, encryptedInvitationCode } = clinicPatient;
    const encryptedLocalPatientProfileId = encrypt(id, pubKeyObj);
    let { invitationCode } = clinicPatient;
    let hasInvitationCode = !!invitationCode;
    if (!invitationCode) {
      if (encryptedInvitationCode) {
        const { encryptedPrivateKey, passphrase } = clinicMembership;
        const prvKeyObj = getKeyFromPem(encryptedPrivateKey, passphrase);
        invitationCode = decrypt(encryptedInvitationCode, prvKeyObj);
        hasInvitationCode = true;
      } else {
        invitationCode = uuid();
        const { email } = sharingRequest.patient;
        yield put(actions.updatePatient(clinicPatient, { email, invitationCode }, clinicMembership));
        const updatePatientResult = yield take([
          actionTypes.UPDATE_PATIENT_SUCCESS,
          actionTypes.UPDATE_PATIENT_ERROR,
        ]);
        if (updatePatientResult.type === actionTypes.UPDATE_PATIENT_ERROR) {
          yield put(actions.bindSharingRequestWithClinicPatientError(updatePatientResult.error));
          return;
        }
        clinicPatient = updatePatientResult.payload.clinicPatient;
      }
    }

    if (hasInvitationCode) {
      const existingSharingRequest = yield call(runFetchSharingRequest, invitationCode);
      if (existingSharingRequest) {
        const err = new LocalError({ code: 'CannotBindPatientAlreadyHasSharingRequest' });
        yield put(actions.bindSharingRequestWithClinicPatientError(err));
        yield call(App.dispatchError, err, messages);
        return;
      }
    }

    const requestUrl = `/api/SharingRequest/clinic/${sharingRequest.sharingRequestId}/bindWithClinicPatientProfile`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : {
        encryptedLocalPatientProfileId,
        invitationCode,
      },
    });
    yield put(actions.bindSharingRequestWithClinicPatientSuccess(clinicPatient));
  } catch (err) {
    yield put(actions.bindSharingRequestWithClinicPatientError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* sync({ payload }) {
  const sharingRequest = get(payload, 'sharingRequest');
  const externalDataSourcesIds = get(payload, 'sharingRequest.patient.externalDataSourcesIds', []);
  const sharingRequestId = get(payload, 'sharingRequest.sharingRequestId');
  const oldTotalReadingsCount = get(payload, 'phiSet.summaryData.totalReadingsCount');
  const externalDataSourcesTokens = get(payload, 'sharingRequest.externalDataSources', []);
  const patient = get(payload, 'activePatient');

  const { onlyCloudDrive } = payload;
  let { phiSet, phiSetDocumentId } = payload;

  yield put(CloudDrive.actions.sync({
    ...payload,
    createPhiSetAction              : actions.setPhiSet,
    successAction                   : actions.setImportedReadings,
    notesSuccessAction              : actions.setNotes,
    timeSeriesResourcesSuccessAction: actions.setTimeSeriesResources,
  }));
  let currentTotalReadingsCount = 0;
  const cloudDriveSyncResult = yield take([
    CloudDrive.actionTypes.SYNC_SUCCESS,
    CloudDrive.actionTypes.SYNC_ERROR,
  ]);

  if (cloudDriveSyncResult.type === CloudDrive.actionTypes.SYNC_SUCCESS) {
    phiSet = get(cloudDriveSyncResult, 'payload.updatedPhiSet', phiSet);
    phiSetDocumentId = get(cloudDriveSyncResult, 'payload.phiSetDocumentId', phiSetDocumentId);
    currentTotalReadingsCount = get(phiSet, 'summaryData.totalReadingsCount');

    if (!patient.isFullyLinkedInStatisticalDb) {
      const clinicMembership = get(payload, 'activeClinicMembership');
      yield call(linkPatientToStatisticalDb, patient, phiSet, clinicMembership, sharingRequest);
    }
  }

  if (!onlyCloudDrive) {
    yield put(DataSources.actions.sync({
      ...payload,
      phiSet,
      phiSetDocumentId,
      externalDataSourcesIds,
      sharingRequestId,
      externalDataSourcesTokens,
      successAction           : actions.setImportedReadings,
      successRelatedDataAction: actions.setImportedRelatedData,
      // showErrorAlert          : false,
    }));
    const dataSourcesSyncResult = yield take([
      DataSources.actionTypes.SYNC_SUCCESS,
      DataSources.actionTypes.SYNC_ERROR,
    ]);

    if (dataSourcesSyncResult.type === DataSources.actionTypes.SYNC_SUCCESS) {
      const dssReadingsCount = get(dataSourcesSyncResult, 'payload.updatedPhiSet.summaryData.totalReadingsCount');
      currentTotalReadingsCount = Math.max(currentTotalReadingsCount, dssReadingsCount);
    }
  }

  if (currentTotalReadingsCount > oldTotalReadingsCount) {
    const clinic = get(payload, 'activeClinicMembership.clinic');
    const { clinicId, name: clinicName, publicKey } = clinic;
    const { firstName: patientFirstName, lastName: patientLastName } = patient;
    const pubKeyObj = getKeyFromPem(publicKey);
    const encryptedClinicPatientProfileId = encrypt(patient.id, pubKeyObj);
    const readingsCount = currentTotalReadingsCount - oldTotalReadingsCount;
    yield call(NotificationsService.sendClinicPatientNewReadingsAddedNotification, {
      clinicId,
      clinicName,
      patientFirstName,
      patientLastName,
      encryptedClinicPatientProfileId,
      readingsCount,
    });
  }
}


function* syncQueue() {
  const buffer = buffers.sliding(2);
  const channel = yield actionChannel(actionTypes.SYNC, buffer);
  let currentSyncPhiSetReferenceKey = null;
  while (true) {
    const [channelAction, stopAction] = yield race([take(channel), take(actionTypes.STOP_SYNC_QUEUE)]);
    if (stopAction) {
      break;
    }
    const { phiSetReferenceKey } = channelAction.payload;
    if (phiSetReferenceKey !== currentSyncPhiSetReferenceKey) {
      currentSyncPhiSetReferenceKey = phiSetReferenceKey;
      yield call(sync, channelAction);
    }
    if (buffer.isEmpty()) {
      currentSyncPhiSetReferenceKey = null;
      yield put(actions.syncFinish());
    }
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* storePatientInfo({ payload }) {
  let storePatientInfoError = false;
  const { newPatientValues, clinicMembership, patient, patientHealthData } = payload;
  const { clinicHcpMembershipId, clinic } = clinicMembership;
  const { publicKey } = clinic;
  const pubKeyObj = getKeyFromPem(publicKey);
  const encryptedClinicPatientProfileId = encrypt(patient.id, pubKeyObj);

  let invitationCode;
  let sharingRequest;

  if (newPatientValues.email && patient.email !== newPatientValues.email) {
    sharingRequest = yield call(fetchSharingRequest, patient, clinicMembership);
    invitationCode = uuid();
    newPatientValues.encryptedInvitationCode = encrypt(invitationCode, pubKeyObj);
  }

  yield put(actions.updatePatient(patient, newPatientValues, clinicMembership));
  const resultPatient = yield take([
    actionTypes.UPDATE_PATIENT_SUCCESS,
    actionTypes.UPDATE_PATIENT_ERROR,
  ]);

  if (resultPatient.type === actionTypes.UPDATE_PATIENT_ERROR) {
    const errLocal = new LocalError({ code: 'FailedUpdatePatient' });
    yield call(App.dispatchError, errLocal, messages);
    storePatientInfoError = true;
  } else if (invitationCode) {
    yield put(actions.createSharingRequest(
      clinicHcpMembershipId,
      invitationCode,
      newPatientValues.email,
      encryptedClinicPatientProfileId,
      newPatientValues,
      patientHealthData,
    ));
    const resultCreateSharingRequest = yield take([
      actionTypes.CREATE_SHARING_REQUEST_SUCCESS,
      actionTypes.CREATE_SHARING_REQUEST_ERROR,
    ]);
    if (resultCreateSharingRequest.type === actionTypes.CREATE_SHARING_REQUEST_ERROR) {
      const errLocal = new LocalError({ code: 'FailedCreateSharingRequest' });
      yield call(App.dispatchError, errLocal, messages);
      storePatientInfoError = true;
    }

    if (sharingRequest) {
      yield call(cancelSharingRequest, sharingRequest);
    }
  }

  if (storePatientInfoError) {
    yield put(actions.storePatientInfoError());
    return;
  }

  yield put(actions.storePatientInfoSuccess());
}

//----------------------------------------------------------------------------------------------------------------------

function* storeAndPushNote({ payload }) {
  try {
    const {
      notes,
      phiSet,
      phiSetDocumentId,
      phiSetReferenceKey,
      phisetVisitId,
      sharingRequest,
      storageProvider,
      accessToken,
      clinicMembership,
    } = payload;
    const isAnyNotePublic = notes.some((note) => !note.payload.isPrivate);

    forEach(notes, (note) => {
      if (!note.noteId) note.noteId = uuid();
    });

    const activeVisit = yield select(Visit.selectors.activeVisit);
    const activePhisetVisitId = get(activeVisit, 'phisetVisitId', null);

    yield put(CloudDrive.actions.storeNotes(
      notes,
      phiSet,
      phiSetDocumentId,
      phisetVisitId,
      {
        phiSetReferenceKey,
        storageProvider,
        accessToken,
      },
      actions.setNotes,
    ));

    if (!activePhisetVisitId || activePhisetVisitId !== phisetVisitId) {
      if (sharingRequest && sharingRequest.sharingStatus === 'Approved') {
        yield put(CloudDrive.actions.pushNotes(notes, phisetVisitId, sharingRequest));

        const { sharingRequestId } = sharingRequest;
        const clinicName = get(clinicMembership, 'clinic.name');
        if (isAnyNotePublic) {
          yield call(NotificationsService.sendNoteWrittenByHcpNotification, { clinicName, sharingRequestId });
        }
      }
    }
    yield put(actions.storeAndPushNoteSuccess());
  } catch (err) {
    yield put(actions.storeAndPushNoteError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchPatientCountryLocalization({ payload }) {
  try {
    const { countryId } = payload;
    const countries = yield select(App.selectors.countries);
    const country = find(countries, { countryId });
    const countryCode = get(country, 'alpha2Code');
    const [countrySettings, patientCustomIdentifiers, informationTemplate, clinicPatientTemplate] = yield all([
      call(CountryLocalizationService.fetchCountrySettings, countryId),
      call(CountryLocalizationService.fetchPatientCustomIdentifiers, countryId),
      call(CountryLocalizationService.fetchInformationTemplate, countryCode, Account.constants.SCOPE_NAMES.PERSONAL),
      call(CountryLocalizationService.fetchClinicPatientTemplate, countryCode),
    ]);
    if (countrySettings) {
      countrySettings.countryId = countryId;
    }
    yield put(actions.fetchPatientCountryLocalizationSuccess({
      countrySettings,
      informationTemplate,
      patientCustomIdentifiers,
      clinicPatientTemplate,
    }));
  } catch (err) {
    yield put(actions.fetchPatientCountryLocalizationError(err));
    yield call(App.dispatchError, err);
  }
}


function* fetchPayers({ payload }) {
  try {
    const { countryId } = payload;
    const payers = yield call(CountryLocalizationService.fetchPayers, countryId);
    yield put(actions.fetchPayersSuccess(payers));
  } catch (err) {
    yield put(actions.fetchPayersError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* addPatientToFavorite({ payload }) {
  const { patient, clinicMembership } = payload;
  const { isFavorite } = patient;
  try {
    yield put(actions.updatePatient(patient, { isFavorite: !isFavorite }, clinicMembership));
    const updatePatientResult = yield take([
      actionTypes.UPDATE_PATIENT_SUCCESS,
      actionTypes.UPDATE_PATIENT_ERROR,
    ]);

    if (updatePatientResult.type === actionTypes.UPDATE_PATIENT_ERROR) {
      yield put(actions.addPatientToFavoriteError(updatePatientResult.error));
      const message = isFavorite
        ? messages.infos.errorRemovePatientFromFavorite
        : messages.infos.errorAddPatientToFavorite;
      showToast(TOAST_TYPES.ERROR, App.messages.toast.header.error, message);
      return;
    }

    yield put(actions.addPatientToFavoriteSuccess(updatePatientResult.payload.clinicPatient));

    const message = isFavorite
      ? messages.infos.removePatientFromFavorite
      : messages.infos.addPatientToFavorite;
    showToast(TOAST_TYPES.SUCCESS, App.messages.toast.header.success, message);
  } catch (err) {
    yield put(actions.addPatientToFavoriteError(err));
    const message = isFavorite
      ? messages.infos.errorRemovePatientFromFavorite
      : messages.infos.errorAddPatientToFavorite;
    showToast(TOAST_TYPES.ERROR, App.messages.toast.header.error, message);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.FETCH_PATIENTS, fetchPatients);
  yield takeLatest(actionTypes.FETCH_ENROLLING_SHARING_REQUESTS, fetchEnrollingSharingRequests);
  yield takeLatest(actionTypes.ADD_PATIENT, addPatient);
  yield takeLatest(actionTypes.ENROLL_PATIENT, enrollPatient);
  yield takeLatest(actionTypes.MERGE_PATIENT, mergePatient);
  yield takeLatest(actionTypes.UPDATE_PATIENT, updatePatient);
  yield takeLatest(actionTypes.PREVIEW_PATIENT_OPEN_TAB, previewPatientOpenTab);
  yield takeLatest(actionTypes.START_PATIENT_VISIT, startPatientVisit);
  yield takeLatest(actionTypes.END_PATIENT_VISIT, endPatientVisit);
  yield takeLatest(actionTypes.REMOVE_PATIENT, removePatient);
  yield takeLatest(actionTypes.REASSIGN_HCP, reassignHcp);
  yield takeLatest(actionTypes.ACTIVATE_PATIENT, activatePatient);
  yield takeLatest(actionTypes.SET_PHI_SET, setPhiSet);
  yield takeLatest(actionTypes.CREATE_SHARING_REQUEST, createSharingRequest);
  yield takeLatest(actionTypes.REMOVE_SHARING_REQUEST, removeSharingRequest);
  yield takeLatest(actionTypes.RESEND_SHARING_REQUEST, resendSharingRequest);
  yield takeLatest(actionTypes.CHECK_SHARING_REQUEST_APPROVE, checkSharingRequestApprove);
  yield takeLatest(actionTypes.BIND_SHARING_REQUEST_WITH_CLINIC_PATIENT, bindSharingRequestWithPatientProfile);
  yield takeLatest(actionTypes.START_SYNC_QUEUE, syncQueue);
  yield takeLatest(actionTypes.STORE_PATIENT_INFO, storePatientInfo);
  yield takeEvery(actionTypes.STORE_AND_PUSH_NOTE, storeAndPushNote);
  yield takeLatest(actionTypes.FETCH_PATIENT_COUNTRY_LOCALIZATION, fetchPatientCountryLocalization);
  yield takeLatest(actionTypes.FETCH_PAYERS, fetchPayers);
  yield takeLatest(actionTypes.ADD_PATIENT_TO_FAVORITE, addPatientToFavorite);
}

export default [
  sagas,
];
