import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Avatar from 'components/Avatar';
import App from 'modules/App';
import { REMOVE_PATIENT_FORM } from '../../constants';
import styles from '../ModalCommon.pcss';


const Person: FC = () => {
  const formValues = useSelector(App.selectors.formSelector(REMOVE_PATIENT_FORM));
  const patient = (formValues && formValues.contextData) || {};
  const { avatar, firstName, lastName } = patient;

  return (
    <div className={cn('row align-items-center', styles.person)}>
      <div className="col-auto pr-0">
        <Avatar
          avatarImg={avatar}
          name={[firstName, lastName]}
          className={styles.item__avatar}
          imgClassName={styles.item__avatar__img}
          initialsClassName={styles.item__avatar__initials}
        />
      </div>
      <div className="col">
        <p className={styles.person__name}>{ firstName } { lastName }</p>
      </div>
    </div>
  );
};

export default Person;
