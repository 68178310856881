import React, { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import CheckIcon from 'svg/check.svg';
import XIcon from 'svg/x.svg';
import * as selectors from '../../../selectors';
import styles from './Merging.pcss';


const Indicator: FC = () => {
  const isInProgress = useSelector(selectors.isMergePatientInProgress);
  const hasErrors = useSelector(selectors.hasMergePatientErrors);

  if (isInProgress) {
    return (
      <div className={styles.loaderContainer}>
        <img src="/assets/svg/loader.svg" className={`${styles.loader} rotatingLoader`} alt="" />
      </div>
    );
  }

  const Icon = hasErrors ? XIcon : CheckIcon;
  return (
    <div className={cn(styles.indicatorBackground, { [styles.error]: hasErrors })}>
      <div className={styles.indicator}>
        <div className={styles.loaderContainer}>
          <Icon className={styles.indicatorIcon} />
        </div>
      </div>
    </div>
  );
};

export default Indicator;
