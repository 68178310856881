import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AppContext } from 'context';
import history from 'helpers/history';
import BackButton from 'components/BackButton';
import Button from 'components/Form/Button';
import Tabs from 'components/Tabs';
import urlRouteShape from 'shapes/urlRouteShape';
import App from 'modules/App';
import ExternalDataSources from 'modules/DataSources/partials/ExternalDataSources';
import ContourCloudInfo from 'modules/DataSources/partials/ContourCloudInfo';
import ReauthorizeContourCloudButton from 'modules/Patient/partials/ReauthorizeContourCloudButton';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import messages from '../../messages';
import ClinicsMemberships from './ClinicsMemberships';
import PatientProfile from './PatientProfile';
import ProfileSettings from './ProfileSettings';
import styles from './AccountSettings.pcss';


class AccountSettings extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    view                     : PropTypes.string,
    views                    : PropTypes.arrayOf(PropTypes.string).isRequired,
    // Implicit props
    activeProfileMainUrlRoute: urlRouteShape,
    route                    : App.shapes.route,
    // Implicit actions
    onSignOut                : PropTypes.func,
  };


  onChangeView(view) {
    let url;
    if (view === 'settings') {
      url = this.context.getUrl('account-settings');
    } else {
      url = this.context.getUrl('account-settings-view', { view });
    }
    history.push(url);
  }


  get backUrl() {
    const { getUrl } = this.context;
    const { name, params } = this.props.activeProfileMainUrlRoute;
    return getUrl(name, params);
  }


  renderHeader() {
    return (
      <header className="pageHeader row">
        <div className="col">
          <h1 className="text--h1"><FormattedMessage {...messages.headers.profileSettings} /></h1>
        </div>
        <div className="col-auto">
          <Button
            styleModifier="primary"
            labelMessage={messages.buttons.signOut}
            className="btn--filled"
            onClick={this.props.onSignOut}
          />
        </div>
      </header>
    );
  }


  renderView() {
    if (this.props.view === 'clinics') {
      return <ClinicsMemberships />;
    }
    if (this.props.view === 'patientProfile') {
      return <PatientProfile />;
    }
    if (this.props.view === 'dataSources') {
      return (
        <section>
          <div className="mb-9">
            <ContourCloudInfo />
            <ReauthorizeContourCloudButton />
          </div>
          <ExternalDataSources consumer="Personal" />
        </section>
      );
    }
    return <ProfileSettings />;
  }


  render() {
    return (
      <div>
        <BackButton
          to={this.backUrl}
        />
        { this.renderHeader() }
        <App.components.AlertsBus isGlobal className="mb-8" />
        <Tabs
          activeItem={this.props.view}
          items={this.props.views}
          messages={messages.nav}
          action={(activeView) => this.onChangeView(activeView)}
        />
        <div className="pt-6">
          { this.renderView() }
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  activeProfileMainUrlRoute: selectors.activeProfileMainUrlRoute(state),
  route                    : App.selectors.route(state),
});


const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => dispatch(actions.signOut()),
});


const ConnectedAccountSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettings);


export default withStyles(styles)(ConnectedAccountSettings);
