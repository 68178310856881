import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import App from 'modules/App';
import Account from 'modules/Account';
import CloudDrive from 'modules/CloudDrive';
import * as selectors from '../../../selectors';
import messages from '../../../messages';


const AuthorizeClinicCloudDrive: FC = () => {
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const { clinic } = activeClinicMembership;

  const isInProgress = useSelector(selectors.isReauthorizeClinicCloudDriveInProgress);

  const authorizeCloudDrive = useAction(
    CloudDrive.actions.authorize,
    clinic.storageProvider,
    `clinicReAuth-${clinic.organizationUID}-${clinic.name}`,
  );

  return (
    <Button
      styleModifier="primary"
      className="btn--filled mr-6 mt-6"
      labelMessage={messages.buttons.reauthorizeCloudDrive}
      onClick={authorizeCloudDrive}
      isInProgress={isInProgress}
    />
  );
};


const ClinicCloudDrive: FC = () => {
  const activeClinicMembership = useSelector(Account.selectors.activeClinicMembership);
  const isAdmin = get(activeClinicMembership, 'isAdmin', false);
  const storageProvider = get(activeClinicMembership, 'clinic.storageProvider');
  if (!isAdmin || !storageProvider) {
    return null;
  }

  const storage = <b>{ get(App.constants.CLOUD_DRIVES_LABELS, storageProvider, storageProvider) }</b>;

  return (
    <section>
      <h2 className="section__header"><FormattedMessage {...messages.headers.cloudDrive} /></h2>
      <p className="text--large">
        <FormattedMessage
          {...messages.infos.dataStored}
          values={{ storage }}
        />
      </p>
      <AuthorizeClinicCloudDrive />
    </section>
  );
};

export default ClinicCloudDrive;
