import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useProgressSuccess } from 'hooks';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as selectors from '../../selectors';
import messages from '../../messages';


interface Props {
  onClose: () => void,
}


const Actions: FC<Props> = ({ onClose }) => {
  const isInProgress = useSelector(selectors.isConnectInProgress);
  const hasErrors = useSelector(selectors.hasConnectErrors);

  useProgressSuccess(isInProgress, hasErrors, onClose);

  return (
    <div className="modal__actions row">
      <div className="col-6">
        <Button
          styleModifier="primary"
          className="btn--block"
          labelMessage={App.messages.buttons.close}
          isDisabled={isInProgress}
          onClick={onClose}
        />
      </div>
      <div className="col-6">
        <Button
          styleModifier="primary"
          className="btn--block btn--filled"
          type="submit"
          labelMessage={messages.buttons.connect}
          isInProgress={isInProgress}
        />
      </div>
    </div>
  );
};

export default memo(Actions);
